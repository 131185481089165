.academic_container{
    /* margin-top: 30px; */
    padding: 20px;
}
.academic_cals_container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.academic_reports{
    width:70%
}



@media(max-width:1024px){
    .academic_reports{
        width: 100%;
    }
}
@media(max-width:768px){
    .academic_reports{
        width:70%
    }
}

@media(max-width:500px){
    .academic_reports{
        width: 100%;
    }
}
