.cont_card {
    width: 300px;
    /* height: 220px; */
    margin: 15px 20px;
  
    overflow: hidden;
  
    border-radius: 28px;
    box-shadow: 1.9px 1.9px 2.2px rgba(0, 0, 0, 0.07),
    4.7px 4.7px 5.3px rgba(0, 0, 0, 0.05), 8.8px 8.8px 10px rgba(0, 0, 0, 0.042),
    15.6px 15.6px 17.9px rgba(0, 0, 0, 0.035),
    29.2px 29.2px 33.4px rgba(0, 0, 0, 0.028),
    70px 70px 80px rgba(0, 0, 0, 0.02);

  }
  .card_body {
    display: block;
    /* width: 300px; */
    height: 240px;
    padding: 30px 20px;
    /* background-color: #d9edff; */
    background-color: #ecf6ff;
  
    overflow: hidden;
  
    position: relative;
    /* z-index: 0; */
  }
  .card_top_head{
    font-size: 20px;
    color: #00134d;
    z-index: 2;
    position: relative;
  }

  .card_body:hover,
  .card_body:hover .card_sub {
    color: #FFF;
    transition: 0.5s ease;
  }

  .card_body:hover,
  .card_body:hover .card_detail {
    color: #FFF;
    transition: 0.5s ease;
  }

  .card_body:hover,
  .card_body:hover .card_title {
    color: #FFF;
    transition: 0.5s ease;
  }
  .card_body:hover,
  .card_body:hover .card_top_head {
    color: #FFF;
    transition: 0.5s ease;
  }

  

  .card_body:hover,
  .card_body:hover .card_sub_link {
    color: #00134d;
    background-color: #ecf6ff;
    transition: 0.5s ease;
    >span{
      color: #00134d;
    }
  }

  .card_body:hover .card_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
  }

  

  .card_title {
    min-height: 87px;
    margin: 10px 0px 10px 0;
  
    overflow: hidden;
  
    font-weight: bold;
    font-size: 28px;
    color: #00134d;
  
    z-index: 2;
    position: relative;
  }
  .card_sub {
    /* display: none; */
    font-size: 18px;
    color: #00134d;
    margin-top:2px ;
    margin-bottom: 4px;

    z-index: 2;
    position: relative;
  }
  .card_sub_link{
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    margin-top: 5px;
    background-color: #00134d;
    border-radius: 5px;
  
    z-index: 2;
    position: relative;
    >span{
      color: #FFF;
    }
  }
  .card_detail {
    text-decoration: none;
    font-weight: bold;
    color: #00134d;
  
    -webkit-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease
  }
  .card_bg {
    height: 128px;
    width: 128px;
    background-color: #00134d;
  
    z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;
  
    border-radius: 50%;
  
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
  }

  @media(max-width:500px){
    .card_sub_link{
      font-size: 16px;
      padding: 7px;
    }
    .card_title{
      font-size: 26px;
    }
  }

  @media(max-width:400px){
    .card_bg{
      top: -75px;
      right: -75px;
    }
  }

  @media(max-width:375px){
    .card_bg{
      top: -85px;
      right: -45px;
    }
  }
  @media(max-width:345px){
    .card_bg{
      top: -75px;
      right: -35px;
    }
    .card_title{
      font-size: 24px;
    }
  }
