.dropdown {
    position: relative;
    display: inline-block;
}

nav.navbar-static-top {
    margin-bottom: 0px;
    /* position: static; */
}


/* Dropdown Content (Hidden by Default) */

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 220px;
    /* width: fit-content; */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 20;
}

.dropdown-content a:hover {
    /* background-color: #ff6600; */
    color: #00134d;
    font-weight: bold;
}


/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content {
    display: block;
    z-index: 30;
}


/* Change the background color of the dropdown button when the dropdown content is shown */


/*  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
} */


/* Links inside the dropdown */

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content .a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.navigation {
    position: fixed !important;
    width: 100%;
    top: 0 !important;
    z-index: 970;
}

.badge {
    background-color: #ff6600 !important;
    padding: 1px 5px;
}

.sathiyan {
    background-color: #ffffff !important;
    box-shadow: 0 2px 10px black;
    height: 38px;
}

.navbar {
    background-color: #00134d;
}

.navbar-nav {
    width: 70%;
    display: flex;
    justify-content: space-around;
    float: right;
}

.good {
    margin-left: 80%;
}

.nav-item {
    margin-left: 15 px;
}

.nav-link {
    color: white;
    text-align: center;
    font-weight: bolder;
    cursor: pointer;
    border-bottom: 2px solid #ff6600;
}
.nav-link:focus {
    color: white;
}

.link {
    color: #00134d;
    border: none;
    /* border-bottom: 2px solid #00134d; */
}
.link:focus {
    color: #00134d;
    border: none;
    /* border-bottom: 2px solid #00134d; */
}

.nav-link:hover {
    background-color: #ff6600;
    font-size: 100%;
    color: #00134d;
    font-weight: bolder;
    /* transition-duration: 0.3s; */
}

.link:hover {
    background-color: #00134d;
    color: white;
}

*,
 :after,
 :not(.fa):before {
    box-sizing: border-box;
    color: #4d4d4d;
    font-family: "Gotham A", "Gotham B", sans-serif;
}

/* img[Attributes Style] {
    width: 302px;
    aspect-ratio: auto 302 / 416;
    height: 416px;
} */

.responsive {
    width: 100%;
    height: auto;
    min-height: 70px;
    min-width: 350px;
    display: block;
}

.responsive1 {
    display: none;
}

.logo {
    margin-left: -4px;
    z-index: 100;
    position: fixed;
    top: 0;
    width: 27%;
}
.navbar-toggler{
    cursor: pointer;
}
.navbar-toggler:focus {
    border: 0!important;
    box-shadow: none;
}

.idiot {
    display: none;
}

@media (max-width: 1199px) {
    .sathiyan {
        display: none;
    }
    .nav-item {
        margin-bottom: 15px;
    }
    .navbar-nav {
        width: 100%;
    }
    .nav-link {
        margin-top: 1 rem;
    }
    .responsive {
        position: fixed;
        top: 0;
        width: 100%;
        height: auto;
        min-height: 70px;
        max-width: 300px;
        display: block;
    }
    .idiot {
        display: block;
    }
    .offcanvas-collapse {
        position: fixed;
        top: 54px;
        opacity: 90%;
        /* Height of navbar */
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-top: 2rem;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: #01134d;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    }
    .offcanvas-collapse.open {
        visibility: visible;
        transform: translateX(-100%);
    }
}

@media (max-width: 640px) {
    .responsive {
        display: none;
    }
    .responsive1 {
        display: block;
        max-width: 110px;
        margin-left: -3px;
        width: 100%;
        height: auto;
        position: fixed;
        top: 0;
    }
}