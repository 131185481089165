.login-page {
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.login_err_msg{
  color: red;
  font-size: 14px;
  font-weight: 700;
  padding: 0 0.75rem;
}

section.login-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

section.login-side {
  background: url(../../assets/pictures/login_bg.png) no-repeat;
  background-size: 100% 102%;
}

.login-side img {
  width: 80%;
  max-width: 80%;
}

.login-container {
  max-width: 450px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-title {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  color: #00134d;
  font-family: 'Montserrat', sans-serif;
}

.login-separator {
  width: 150px;
  height: 4px;
  background-color: #ff6600;
  margin: 24px;
}

.login-welcome-message {
  text-align: center;
  font-size: 1.1em;
  line-height: 28px;
  margin-bottom: 30px;
  color: #696969;
  font-family: 'Montserrat', sans-serif;
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-control {
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  border: none;
}

.login-input,
.login-submit {
  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;
}

.login-input {
  width: 100%;
  background: #e6e6e6;
  color: #333;
  letter-spacing: 0.5px;
  padding: 14px 64px;
  font-family: 'Montserrat', sans-serif;
}

.login-input ~ i {
  position: absolute;
  left: 32px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  transition: color   0.4s;
}

.login-input:focus ~ i {
  color: #ff6600;
}

button.login-submit.enabled{
  color: #fff;
  padding: 14px 64px;
  margin: 32px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  background: #ff6600;
  /* background-image: linear-gradient(to right, #ff6600, #fff); */
  cursor: pointer;
  transition: opacity 0.4s;
}

button.login-submit.enabled:hover {
  opacity: 0.9;
  /* background-image: linear-gradient(to right, #ff6600, #00134d); */
  background: #00134d;
  color: white;
  text-transform: lowercase;
}
button.login-submit.disabled {
  color: #fff;
  padding: 14px 64px;
  margin: 32px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  background: #ff6600;
  transition: opacity 0.4s;
  /* Define styles for disabled state */
  /* Example: Add opacity and change cursor */
  opacity: 0.5;
  cursor: not-allowed;
}
.captcha{
  margin-left: 50px;
}

.forgot:hover{
  color: #ff6600;
}
@media (max-width: 780px) {

  .login-page {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .login-side {
      display: none;
  }
}
@media (max-width: 400px){
  .login-title{
    font-size: 2.5em;
  }
  .login-input{
    font-size: 15px;
  }
  .login-container{
    padding: 10px;
  }
  
}