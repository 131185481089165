.clubs_desc {
  background-color:#00134d;
  padding: 80px;
}

.clubs_desc p {
  color: white;
  font-size: 20px;
  line-height: 1.5rem;
  text-align: center;
}

@media (max-width: 600px) {
  .clubs_desc {
    padding: 20px;
  }

  .clubs_desc p {
    font-size: 14px;
  }
}
