*{
  margin: 30px;
}

.std_mini_proj_fb_container {
max-width: 800px;

margin: 40px auto;
padding: 20px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
background-color: #fff;
}

.std_mini_proj_fb_form {
display: flex;
flex-direction: column;
}

.std_mini_proj_fb_error {
color: red;
font-size: 14px;
margin-top: -30px;
}

.std_mini_project_fb_error p,.std_mini_proj_sem_err p{

color: red;
font-size: 14px;
margin-top :5px;
margin-left: 51%;
}

.std_mini_proj_fb_row {
display: flex;
flex-wrap: wrap;
justify-content: space-around;
margin-bottom: 15px;
}

.std_mini_proj_fb_input, .std_mini_proj_fb_select {
width: 49%;
padding: 12px;
border: 1px solid #ccc;
border-radius: 5px;
}

.std_mini_proj_fb_input_full{
  width: 100%;
  padding: 12px;
  /* margin: 50px,0px; */
}


.std_mini_proj_fb_instruction{
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.std_mini_proj_fb_feedback {
margin-top: 30px;
}

.std_mini_proj_fb_question {
margin-bottom: 20px;
}
.std_mini_proj_fb_subtitle {
font-weight: bold;
font-size: 20px;
margin-top: 25px;
text-align: center;
}

.std_mini_proj_fb_instruction{
text-align: center;
margin-bottom: 15px;
margin-top:10px;
}


.custom-radio {
  display: inline-block;
  width: 20px; /* Adjust the outer circle size */
  height: 20px;
  border: 2px solid #00134d;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
  text-align: center;
  line-height: 18px; /* Adjust to center the number vertically */
  font-size: 12px; /* Font size for the number */
  color: #00134d;
  background-color: white;
  font-weight: bold;
}

input[type="radio"]:checked + .custom-radio {
  background-color: #00134d;
  color: white; /* Change number color when selected */
}

input[type="radio"] {
  display: none;
}

label {
  display: flex;
  align-items: center;
}

.std_mini_proj_fb_btn{
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
.std_mini_proj_fb_row {
  flex-direction: column;
}

.std_mini_proj_fb_input, .std_mini_proj_fb_select {
  width: 100%;
}
.std_mini_proj_fb_two_line{
  margin-top: 10px;
}
.std_mini_project_fb_error p, .std_mini_proj_sem_err p{
  margin-left: auto;
}
}