.scholarcard{
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* width: 800px; */
    /* flex-wrap: wrap; */
    justify-content: space-between;
    /* border-radius: 5px; */
    margin-bottom: 20px;
    
}
.schcard{
    position: relative;
    width: 300px;
    height: 400px;
    background: #00134d;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}
.schcard .schimg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    
}
.schcard .schimg img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    border-radius: 5px;
}
.schcard:hover .schimg img{
    opacity: 0;
}
.schcard .schcontent{
    position: absolute;
    bottom: 20px;
    left: 10%;
    width: 80%;
    height: 60px;
    background: #00134d;
    transition: 0.5s;
    overflow: hidden;
    color: white;

    padding: 18px;
    box-sizing: border-box;
    border-radius: 50px;
    text-align: center;
}

.schcard:hover .schcontent{
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 5px;
}

.schcard .schcontent h3{
    margin: 0;
    padding: 0;
    font-size: 23px;
    color: #fff;
    margin-bottom: 40px;
}
.schcard .schcontent:hover h3{
    font-size: 30px;
    margin-top: 20px;
}

.schcard .schcontent p{
    
    margin: 10px 0 0;
    padding: 0;
    opacity: 0;
    line-height: 1.2em;
    transition: 0.5s;
    text-align: center;
    color: #fff;
}
.schcard:hover .schcontent p{
    opacity: 1;
    transition-delay: 0.2s;
    /* text-align: center; */
}