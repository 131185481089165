.Scholar-cards{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.Schtitle{
    padding: 20px;
}
@media (max-width:400px){
    /* .Scholar-cards{
        flex-direction: column;
    } */
    .Schtitle{
        padding: 20px;
        margin-top: 20px;
    }
}