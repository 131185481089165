.faculties{
    /* margin: 50px; */
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile_holder{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.read_button{
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    background-color: #ff6600;
    font-weight: 700;
    color: #00134d;
    box-shadow: 1.9px 1.9px 2.2px rgba(0, 0, 0, 0.07),
    4.7px 4.7px 5.3px rgba(0, 0, 0, 0.05), 8.8px 8.8px 10px rgba(0, 0, 0, 0.042),
    15.6px 15.6px 17.9px rgba(0, 0, 0, 0.035),
    29.2px 29.2px 33.4px rgba(0, 0, 0, 0.028),
    70px 70px 80px rgba(0, 0, 0, 0.02);
}

.read_button:hover{
    transform: scale(1.1);
    transition: 0.5s;
}