.collab_fb_body{
    max-width: 800px;
    margin:  40px auto;
    padding: 20px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;

}

.collab_fb_form{
    display: flex;
    flex-direction: column;
}
.collab_fb_row{

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 10px;

}

.collab_fb_error {
  color: red;
  font-size: 14px;
  margin-top: -5px;
}
.collab_fb_name_error{
  color: red;
  font-size: 14px;
  margin-top: 10px;
  padding-left: 5px;
  margin-right: auto;
}

.collab_fb_input, .collab_fb_select{
    width: 49%;
    padding: 15px;
    margin-bottom: 10px;
}
input{
  border-radius: 5px;
  border: 1px solid #ccc;
}

.collab_fb_input_lgrow{
    width: 99%;
    margin-bottom: 5px;
    padding: 15px;
}
.collab_fb_cmd{
    width: 99%;
    margin-bottom: 5px;
    padding: 30px;

}

.collab_fb_subheading{
  font-weight: bold;
  font-size: 20px;
  margin-top: 25px;
  text-align: center;
}

.collab_fb_instructions{
    text-align: center;
    margin-bottom: 25px;
    margin-top: 10px;
}

.collab_fb_ratings {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    padding: 5px;
  }
  
  .collab_fb_ratings label {
    display: flex;
    align-items: center;
  }
  
  .collab_fb_ratings input {
    margin-right: 5px;
  }
  
  .collab_fb_ratings {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      padding: 5px;
    }
    
    .collab_fb_ratings label {
      display: flex;
      align-items: center;
    }
  
    .collab_fb_btn{
      cursor: pointer;
      text-align: center;
    }
    
    .custom-radio {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 2px solid #00134d;
      border-radius: 50%;
      margin-right: 5px;
      position: relative;
      text-align: center;
      line-height: 18px; 
      font-size: 12px; 
      color: #00134d;
      background-color: white;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 20px;
    }
    input[type="radio"]:checked + .custom-radio {
        background-color: #00134d;
        color: white; /* Change number color when selected */
      }
    
    input[type="radio"] {
      display: none;
    }
    
    label {
      display: flex;
      align-items: center;
    }
.collab_fb_summit{
    margin-top: 15px;

}

@media (max-width: 600px) {
  .collab_fb_row {
    flex-direction: column;
  }

  .collab_fb_input, .collab_fb_select {
    width: 100%;
  }
  .collab_fb_name_error{
    margin-left: auto;
  }
}





