.acad_head{
    font-weight:500;
    font-size: 26px;
    color:#00134d;
    margin-left:50px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.acad_text{
   width:100%;
   margin-left:50px;

}

.acad_tab{
    width:70%;
    margin:60px 0px 50px 50px;
    overflow-x:scroll;
 }
  .acad_tab td:nth-child(2),
  .acad_tab td:nth-child(3) {
    width:70%;
  }

  .flex_col{
    display: flex;
    /* margin-left: 50px; */
    /* margin-right:50px; */
    display: inline;
  }

  .flex_col h6{
    color: #00134d;
    font-weight: bold;
  }

  .flex_col p{
    top:2px;
    margin-left: 0px;
    color:black;
   
  }
  .acad_tab1,.acad_tab2,.acad_tab3{
    width:70%;
    margin:60px 0px 50px 50px;
 }
 .acad_tab1 td:nth-child(3)
 {
    width:20%;
 }
 .acad_tab1 td:nth-child(1)
 {
    width:10%;
 }

.acad_tab2 td:nth-child(3),
.acad_tab3 td:nth-child(3)
 {
    width:30%;
 }
 .acad_tab2 td:nth-child(1),
 .acad_tab3 td:nth-child(1)
 {
    width:10%;
 }

 @media (max-width:1100px) {
   .flex_col p{
      top:2px;
      margin-left: 0px;
      color:black;
    }
    .acad_text{
      width:70%;
   }
 }

 @media (max-width:768px) {
 .acad_tot{
   width:90%;
   margin:50px auto 10px auto;
}
.acad_head h5{
   margin:30px auto 20px 190px;
}
.acad_tab{
   width:95%;
   margin:30px auto 20px auto;
   overflow-x:scroll;
}
.acad_tab2,.acad_tab3{
   width:95%;
   margin:30px auto 20px auto;
}
.acad_tab1{
   width:90%;
   margin:30px auto 20px auto;

}
.flex_col{
   width:100%;
}

 }

 @media (max-width:600px) {
   .acad_head h5{
      margin:30px auto 20px 40px;
   }
   /* .flex_col{
      display: flex;
      margin-left:20px;
    } */
   .acad_tab1{
      width:95%;
      /* overflow-x: scroll; */
      margin:30px auto 20px auto;
   }
   .acad_text{
      width:70%;
   }
 }

 @media (max-width:500px) {
   .acad_tab3,.acad_tab2{
      overflow-x: scroll;

   }
 }

 @media (max-width:550px) {
   .acad_tab1{
      overflow-x: scroll;

   }
 }