.naac_content{
    margin-top: 20px;
    padding:10px;
    width: 75%;
}
.naac_cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 0px;
}