.main {
    max-width: 100%;
    padding: 20px;
    margin-top:20px;
    display: flex;
    flex-direction: column;
  }

  
  .sub-division {
    background-color: #ECF6FF;
    margin-bottom: 20px;
    padding: 20px;
  }

  .sub-division>ul>li{
    color: black;
    line-height: 1.5rem;
    text-align: justify;
  }

  .sub-division h2 {
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 24px;
    color: #00134d;
  }
  .sub-division p {
    margin: 0;
    color: black;
    line-height: 1.5rem;
    text-align: justify;
    font-size: 16px;
  }

  /* Display the cards as flex containers */
.row {
  display: flex;
  flex-wrap: wrap;
}

/* Each column occupies 25% of the available width */
.column {
  flex: 25%;
  padding: 0 10px;
}

.space{
  margin-top: 20px;
}

/* Remove extra left and right margins, due to padding */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
  /* Ensure all cards have equal height */
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Style the card images */
.card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.card h3{
  font-size:x-large;
  color: #00134d;
  text-align: center;
  font-weight: bold;
}

.card p{
  text-align: justify;
  color: black;
}

@media screen and (max-width: 1024px) {
  .column {
    flex: 50%;
    display: block;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  /* Show one card per row on smaller screens */
  .column {
    flex: 100%;
    display: block;
    margin-bottom: 20px;
  }

  .card p{
    font-size: 14px;
  }
}



 