.cgpa_cal {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .cgpa_content {
    font-size: 1.6em;
    color: #00134d;
    margin-bottom: 10px;
  }
  
  .cgpa_deptdrop {
    margin-right: 25px;
    
  }
  
  .cgpa_deptsem_head {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .cgpa_deptsem {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 20px;
  }
  
  .cgpa_form_select {
    padding: 8px;
    text-align: center;
    font-size: 1em;
    border: 3px solid #00134d;
    border-radius: 10px;
    background-color: #d9edff;
    color: #00134d;
    margin-left: 10px;
    appearance: none;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);

  }
  /* .cgpa_form_select option{
    background-color: #d9edff;
  } */

  .sgpa_drop_options{
background-color: #d9edff;
  }

  .sgpa_drop_options:hover{
    background-color: red;
  }

  .cgpa_subjects {
    padding: 50px;
    margin: 1px 0;
  }
  
  .cgpa_subjects h2 {
    color: #00134d;
    margin-bottom: 30px;
  }
  .cgpa_error_msg{
    font-size: 0.5em;
  }
  
  .cgpa_subject {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }
 
.cgpa_subject p1{
  flex: 1;
  margin-bottom: 15px;
  color: #00134d;

}
.cgpa_subject p{
  margin-right: 10px;
}

  .cgpa_grade_drop {
    margin-right: 35%;
    margin-left: 10px;
  }
  
  .cgpa_buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .cgpa_button1{
    margin-top: 10px;
  }
  button {
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background: #00134d;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:disabled {
    background-color: #00134d;
    cursor: not-allowed;
  }
  
  button:hover:not(:disabled) {
    background-color: #ff6600;
    color: #00134d;
  }
  
  .cgpa-progress-bar-container {
    position: absolute;
    right: 9%;
    top: 110%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    
  }
  
  .cgpa_subjects{
    font-size: 20px;
  }
  /* Style for the arrear form container */
.cgpa_arrear_form {
  background-color: #f9f9f9; /* Light background color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the container */
  margin-top: 20px; /* Space above the container */
  width: 300px; 
  margin-left: -15px;
  
}

/* Style for the form input */
.cgpa_arrear_form input[type="text"] {
  width: 100%; /* Full width input */
  padding: 5px; /* Padding inside the input */
  margin-bottom: 10px; /* Space below the input */
  border: 2px solid #ccc; /* Border color */
  border-radius: 10px; /* Rounded corners */
  
  
}

/* Style for the form buttons */
.cgpa_arrear_form button {
  padding: 8px 16px; /* Padding inside the button */
  margin-right: 10px; /* Space between buttons */
  border: none; /* Remove default border */
  border-radius: 10px; /* Rounded corners */
  background-color:#d9edff; /* Button background color */
  color: #00134d; /* Button text color */
  cursor: pointer; /* Pointer cursor on hover */
}

.cgpa_arrear_form button[type="button"] {
  background-color: rgb(253, 98, 98); 
  color: white;
}

/* Style for the error message */
.cgpa_error_message {
  color: red; /* Red text color for error message */
  margin-top: 10px; /* Space above the error message */
  font-size: 14px; /* Font size for error message */
}









  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .cgpa_content {
      font-size: 1.5em;
    }
  
    .cgpa_dropdowns {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  
    .cgpa_form_select {
      margin-left: 10px;

    }
  
    .cgpa_subject {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;
      margin-left: 10%;
    }
  
    .cgpa_grade_drop {
      padding: 3px 4px;
    }
  
    .cgpa_subjects {
      padding: 20px;
      margin-left: 20px;
    }
    .cgpa_deptdrop{
      margin-bottom: 10px;
      margin-left: 30px;
    }
  
    .cgpa_buttons {
      margin-top: 30px;
    }
  
    .cgpa-progress-bar-container {
      position: relative; /* Remove absolute positioning */
      right: auto;
      top: auto;
      transform: none;
      margin-top: 20px;
      margin-left: 35%;
    }
  }
  
  @media (max-width: 480px) {
    .cgpa_content {
      font-size: 1.2em;
    }
    .cgpa_subjects h2{
      font-size: 1.5em;
    }

      .cgpa-progress-bar-container {
        position: relative; /* Remove absolute positioning */
        right: 30%; /* Reset right position */
        top: auto; /* Reset top position */
        transform: none; /* Remove transform */
        margin: 20px auto 0; /* Center the container and add top margin */
        width: 90%; /* Make the container take 90% of the screen width */
      }
    
    
    .cgpa_dropdowns {
      flex-direction: column;
    }
  
    .cgpa_buttons {
      flex-direction: column;
      margin-top: 20px;
    }
    .cgpa_subjects{
      font-size: 1em;
    }
  
    .cgpa_buttons button {
      width: 50%; 
     margin-left: 20%;
    }
    
  }
  @media (max-width: 600px){
    .cgpa-progress-bar-container {
    margin-left: 30%;
    }
  }
  @media (max-width:320px){
    .cgpa-progress-bar-container {
      margin-left: 28%;
     }
     .cgpa_arrear_form{
      width: 110%;
     }
  }
  /* Responsive styles for screen widths between 320px and 480px */

