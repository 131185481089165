.alltitle {
    font-weight: 500;
    font-size: 40px;
    margin-top: 25px;
}
.alltitle .alltitlep {
color:#00134d;
}

.l1 {
  height: 5px;
  background-color: #00134d;
  width: 10%;
  margin-bottom: 10px;
}

.l2 {
  height: 5px;
  background-color: #d9edff;
  width: 10%;
  margin-bottom: 20px;
  
}

@media (max-width: 500px) {
  .alltitlep {
    
      font-size: 28px;
    
  }
  .l1 {
    height: 3px;
    width: 20%;
    margin-bottom: 5px;
  }
  .l2 {
    height: 3px;
    width: 20%;
  }
}

@media (max-width: 321px) {
  .alltitlep {
      font-size: 26px;
    
  }
  .l1 {
    width: 25%;
  }
  .l2 {
    width: 25%;
  }
}
