.governing_council{
    margin-top:20px;
    /* text-align: center; */
    padding:10px;
    
    >h1{
      font-weight: bold;
      text-align: center;
      color:#00134d;
    }
}
.governing_title{
    background-color:#00134d;
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
    padding:15px;
    margin-left: 20px;
    margin-right: 20px;
    >h5{
      color:white;
      font-size: 20px;
    }
  }
  
  .governing_headingsec{
    margin-top: 30px;
    margin:20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .governing_headingsec .governing_content{
    background-color:#D9EDFF;
  }
  
  
  
  .governing_content{
    font-size: 17px;
    border-radius: 10px;
    line-height: 1.5rem;
    padding:20px;
    >ul>li{
      color:black;
      padding:15px;
    }
  }
  
  .governing_pic{
    width:100%;
    height: auto;
    align-items: center;
    justify-content: center;
    /* margin-left: auto;
    margin-right: auto; */
  }
  
  .governing_imghead{
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  /* From this sathiyan have to copy the code */

  .governing_tab{
    margin:20px auto 20px auto;
    width:80%;
  }

  @media (max-width: 1100px){
    .governing_headingsec{
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .governing_imghead{
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      
    }
    .governing_pic{
      width:50%;
      height: auto;
    }
  
    .governing_content{
      height:auto;
      font-size: 17px;
    }
    /* .governing_tab{
      margin-left:;
      margin-right: 30px; 
    }  */
  }
  @media (max-width: 700px) {
    .governing_tab{
      width:90%;
      /* margin-left:auto; */
    }
  }
  
  @media (max-width: 600px) {
    .governing_headingsec{
      flex-direction: column;
    }
  
    .governing_subheading p{
      font-size: 16px;
      text-align: center;
    }
  
    .governing_title h5{
      font-size: 16px;
    }
  
    .governing_content li{
      font-size: 16px;
    }
     
    .governing_tab{
      overflow-x: scroll;
    }
    ::-webkit-scrollbar  {
      width: 10px;
    }
    ::-webkit-scrollbar-track{
      box-shadow: inset 0 0 5px #152029;
      border-radius: 15px;
    }
    ::-webkit-scrollbar-thumb{
      background:#00134d ;
      border-radius: 15px;
      width:2px; /* width of the thumb */
      height: 10px; 
      background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #aaa;
    }
    table th{
      text-align: left;
    }
   }
  