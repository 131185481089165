* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .Physicsbg {
    /* background: url("../../assets/pictures/shbg-tinted.webp"); */
    background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/dept_bgs/shbg-tinted.webp");
    min-height: 550px;
    background-color: #00134d;
    position: relative;
    width: 100%;
    height: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .Physicsheading {
    text-align: center;
    position: absolute;
    color: #fff;
    font-size: 250%;
    top: 48%;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-shadow: 0px 0px 10px #000;
  }
  
  .Physics_sub_heading {
    text-align: center;
    position: absolute;
    color: #ff6600;
    font-size: 150%;
    top: 75%;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-shadow: 0px 0px 10px #000;
  }
  
  
  @media (max-width: 1024px) {
    .Physicsheading {
      top: 35%;
      font-size: 150%;
      font-weight: 500;
    }
    .Physics_sub_heading {
      font-size: 100%;
      font-weight: 500;
    }
    .Physicsbg {
      width: 100%;
      height: 700px;
    }
  }
  @media (max-width: 768px) {
    .Physicsbg {
      width: 100%;
      height: 500px;
    }
    
  }
  
  @media (max-width: 425px) {
    .Physicsbg {
      /* background: url("../../assets/pictures/shbgm-tinted.webp"); */
      background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/dept_bgs/shbgm-tinted.webp");
      min-height: 550px;
      width: 100%;
      height: 750px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }
    
  }
  
  