
body {
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.coe_bg {
  /* background: url("../../assets/pictures/mechbg-tinted.webp"); */
  background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/coe/CoeLapImg-01-01.jpeg.webp");
  min-height: 550px;
  background-color: #00134d;
  position: relative;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.coe_heading {
  text-align: center;
  position: absolute;
  color: #fff;
  font-size: 250%;
  top: 48%;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 0px 0px 10px #000;
}
.process{
  /* padding-left: 20px; */
}

.exam{
  /* padding-left: 35px; */
  line-height: 1.5rem;
}
.coe_sub_heading {
  text-align: center;
  position: absolute;
  color: #ff6600;
  font-size: 150%;
  top: 75%;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 0px 0px 10px #000;
}
.coe_two-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.coe_dark {
  /* display: flex;
    flex-direction: column; */
  /* padding: 40px 20px; */
  width: 75%;
  /* border-radius: 5px 0 5px 0; */
}
.coe_sub_head {
  font-size: 40px;
  font-weight: 500;
  /* width: 100%;  */
  /* text-align: left;  */
  color: #00134d;
}
.coe_two-col p {
  margin-top: 20px;
  width: 100%;
  /* padding: 0 30px; */
}
.coe_dept_content {
  text-align: justify;
  font-size: 18px;
  line-height: 1.5rem;
  color: black;
  /* padding-left: 30px; */

}
.coe_two-col .dept_img_holder {
  width: 50%;
   height: auto; 
   /*align-items: center;
    justify-content: center; */
}
.dept_img {
  width: 100%;
  height: auto;
}
/* .examination{
  text-align: justify;
  font-size: 18px;
  line-height: 1.5rem;
  color: black;


} */

.coe_two-col1 p {
  width: 100%;
  /* padding: 20px 30px; */
}
.coe_progs{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media(max-width:1100px){
  .coe_two-col {
    
    flex-direction: column;
  }
  .coe_two-col p {
    width: 100%;
  }
  .coe_two-col .dept_img_holder {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .coe_heading {
    top: 35%;
    font-size: 150%;
    font-weight: 500;
  }
  .coe_sub_heading {
    font-size: 100%;
    font-weight: 500;
  }
  .coe_bg {
    width: 100%;
    height: 700px;
  }
}
@media (max-width: 768px) {
  .coe_bg {
    width: 100%;
    height: 500px;
  }
  .coe_dark {
    width: 100%;
  }
  
}



@media (max-width: 500px) {
  .coe_bg {
    /* background: url("../../assets/pictures/mechbgm-tinted.webp"); */
    background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/coe/Coemobimg-01-01.jpeg.webp");
    min-height: 550px;
    width: 100%;
    height: 750px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .coe_two-col p {
    padding: 0 5px;
  }
  .coe_two-col1 p {
    padding: 0 5px;
  }
  .coe_sub_head {
    font-size: 28px;
  }
  .coe_dept_content {
    
    text-align: justify;
  font-size: 16px; /* Adjusted font size */
  line-height: 1.5rem;
  color: black;

    
  }
 
  .dept_float_menu {
    display: none;
  }
}

@media (max-width: 321px) {
  .coe_sub_head {
    font-size: 26px;
  }
}

/* Ensure elements do not cause horizontal overflow */
* {
  box-sizing: border-box;
}
