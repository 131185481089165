.civ_indus{
    padding: 20px;
  .civ_intern{
    margin-bottom:50px;
    margin-top: 50px;
    border-radius: 5px;
    padding:10px;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .civ_intern_slide{
      width: 35%;
      padding:10px;
    }
    .civ_intern_content{
        margin-left:10px;
        text-align: justify;
        width:60%;
        line-height: 1.5rem;
        color:black;
    }
}
}
.civil_intern_cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    padding: 20px 0px;
    margin-bottom: 50px;
 } 


 .civil_mou_cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    padding: 20px 0px;
    margin-bottom: 30px;
 } 

 .civ_consult_content{
    font-size: 20px;
    color: black;
    line-height: 1.5rem;
    text-align: justify;
 }

 .civ_consult_layout{
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* gap: 10px; */
 }

 .civ_consult_img_holder{
  max-width: 320px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
 }

 .civ_consult_img_holder_pot{
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
 }

 .civ_consult_img{
  height: auto;
  width: 100%;
 }
 

 @media (max-width:1050px){
    .civ_intern{
      flex-direction: column-reverse;
      .civ_intern_content{
        width: 100% !important;
        margin:10px;
        margin-top: 0px;
      }
      .civ_intern_slide{
        width: 100% !important;
      }
    }
  }

  @media(max-width:720px){
    .civ_consult_img_holder{
      max-width: 500px;
    }
  }

@media(max-width:500px){
  .civ_consult_content{
    font-size: 16px;
  }
}