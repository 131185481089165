/* Information.css */

.newContent {
    color: #ff6600 !important;
    font-weight: bold;
  }
  
  .insideNewContent {
    color: white;
  }
  
  .bulletin-board {
    width: 300px;
    height: 400px;
    overflow: hidden;
    border: 1px solid #ccc;
    position: relative;
  }
  
  .scroll-content {
    list-style: none;
    padding: 0;
    margin: 0;
    animation: scroll 20s linear infinite;
    list-style: disc;
    /* text-wrap: nowrap; */
  }
  
  @keyframes scroll {
    0% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  .list {
    overflow: hidden;
    position: relative;
    height: 150px;
    padding-left: 30px;
    width: 100%;
  }
  
  .msg {
    margin: 15px 0;
    color: white;
    height: fit-content;
    align-items: center;
  }
  
  .important {
    display: flex;
    padding: 0 20px;
  }
  
  .bulletin {
    width: 70%;
    background-color: #01134d;
    margin-right: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .quick {
    width: 30%;
    background-color: #01134d;
    margin-left: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .quick-link {
    color: white;
    width: 80%;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    padding: 5px;
    margin: 5px 0px;
    border: 2px solid white;
    border-radius: 7px;
  }
  
  .quick-link:hover {
    background-color: white;
    color: #01134d;
    font-weight: 700;
  }
  
  .board {
    text-align: center;
    font-size: 25px;
    color: white;
    font-weight: 600;
  }
  
  .line {
    height: 2px;
    background-color: white;
    width: 170px;
    margin: 5px 0;
  }
  
  .impmsg {
    line-height: 1.2rem;
    color: white;
    text-decoration: none;
    font-size: 18px;
  }
  
  .impmsg:hover {
    text-decoration: underline;
    color: white;
  }
  
  @keyframes slide-up {
    from {
      opacity: 0;
      transform: translateY(5%);
    }
    to {
      opacity: 0.8;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 870px) {
    .important {
      flex-direction: column;
    }
    .bulletin {
      width: 100%;
      margin: 0;
    }
    .quick {
      margin: 0;
      margin-top: 10px;
      width: 100%;
    }
  }
  
  @media (max-width: 550px) {
    .board {
      font-size: 20px;
    }
    .line {
      width: 145px;
    }
    .impmsg {
      font-size: 15px;
    }
    .quick-link {
      font-size: 15px;
    }
  }
  