@font-face {
    font-family: 'AquireBold';
    src: local('Trial'), url('../../assets/fonts/AquireBold-8Ma60.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

.sport_team_page_cont{
    padding: 20px;
}

.sport_team_header_sec{
    display: flex;
    justify-content: space-evenly;
}

.sport_team_header_img_holder{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sport_team_header_img{
    width: 100%;
    height: auto;
}

.sport_team_header_title_holder{
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sport_team_header_title{
    font-size: 200px;
    font-weight: 900;
    font-family: 'AquireBold',Trial;
    color: transparent;
    background: linear-gradient(45deg, #0C66A2 45%, #052A44 100%);
    background-clip: text;
}

.akkadians_title{
    font-size: 120px;
}

.maverickz_title{
    font-size: 130px;
}

.phoenix_title{
    font-size: 180px;
}

.sports_team_header_points{
    font-size: 100px;
}

@media(max-width:1250px){
    .sport_team_header_title{
        font-size: 150px;
    }
    .akkadians_title{
        font-size: 100px;
    }
    .maverickz_title{
        font-size: 120px;
    }
    .sports_team_header_points{
        font-size: 92px;
    }
}

@media(max-width:1000px){
    .sport_team_header_title{
        font-size: 130px;
    }
    .akkadians_title{
        font-size: 80px;
    }
    .maverickz_title{
        font-size: 100px;
    }
    .phoenix_title{
        font-size: 100px;
    }
    .sports_team_header_points{
        font-size: 88px;
    }
}
@media(max-width:768px){
    .sport_team_header_sec{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .sport_team_header_img_holder{
        width: 85%;
    }
    .sports_team_header_points{
        font-size: 84px;
    }
}

@media(max-width:600px){
    .sport_team_header_title{
        font-size: 110px;
    }
    .akkadians_title{
        font-size: 80px;
    }
    .sports_team_header_points{
        font-size: 72px;
    }
}

@media(max-width:500px){
    .sport_team_header_img_holder{
        width: 95%;
    }
    .sport_team_header_title{
        font-size: 90px;
    }
    .akkadians_title{
        font-size: 70px;
    }
    .maverickz_title{
        font-size: 80px;
    }
    .sports_team_header_points{
        font-size: 54px;
    }
    .sport_team_page_cont{
        padding: 10px;
    }
}

@media(max-width:400px){
    .sport_team_header_title{
        font-size: 76px;
    }
    .akkadians_title{
        font-size: 50px;
    }
    .maverickz_title{
        font-size: 60px;
    }
    .phoenix_title{
        font-size: 60px;
    }
    .sports_team_header_points{
        font-size: 40px;
    }
}