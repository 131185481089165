
.profile-card{
    width: 220px;
    max-width:220px;
    height: 355px;
    text-align: center;
    background: #fff;
    color: #333;
    border-radius: 5px !important;
    box-shadow: 1.9px 1.9px 2.2px rgba(0, 0, 0, 0.07),
    4.7px 4.7px 5.3px rgba(0, 0, 0, 0.05), 8.8px 8.8px 10px rgba(0, 0, 0, 0.042),
    15.6px 15.6px 17.9px rgba(0, 0, 0, 0.035),
    29.2px 29.2px 33.4px rgba(0, 0, 0, 0.028),
    70px 70px 80px rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.cover{
    display: block;
    width: 100%;
    height: 120px;
    border-radius: 5px 5px 0px 0px;
}
.dp{
    width: 100px;
    border-radius: 50%;
    margin-top: -50px;
    height: 110px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.profile-card h5{
    /* margin-top: 17px; */
    color: black;
    
}
.view-btn{
    display: inline-block;
    text-decoration: none;
    background: #00134d;
    color: #fff;
    width: 100%;
    padding: 10px 0;
    /* margin-top: 5px; */
    border-radius: 0px 0px 5px 5px;
    
    
}
.profile-card p{
    margin-top: 5px;
}
.profile_mail{
    font-size: 14px;
}

.profile_overflow_mail{
    font-size: 12px !important;
}

.view-btn:hover{
    background: #FF6600;
    color: #00134d;
}