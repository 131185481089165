.clubcard_content {
  /* margin-bottom: 30px; */
  padding: 20px;
  border-radius: 20px;
}

.image-container {
  width: 15%;
  /* background-color:#D9EDFF; */
  /* clip-path: polygon(50% 0%, 100% 0, 100% 25%, 75% 50%, 100% 75%, 100% 100%, 50% 100%, 0 100%, 0 75%, 25% 50%, 0 25%); */
  position: relative;
  border-radius: 50%;
  top: 70px;
  left: 70px;
  /* margin: 10px; */
}

.image-container img {
  /* max-width: 100%; */
  width: 100%;
  /* background-color: #00134d; */
  height: auto;
}

.description-container {
  margin-left: 60px;
  margin-right: 60px;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  background-color: #00134d;
  /* width: 30%; */
}



.desc {
  display: flex;
  background-color: #d9edff;
  align-items: center;
  /* padding: 10px; */
  /* border-radius: 15px; */
  margin-left: 60px;
  margin-right: 60px;
  /* box-shadow: 5px 5px 10px 5px #ECF6FF; */
}

.text {
  width: 80%;
}

.text p {
  padding: 20px;
  text-align: justify;
  line-height: 1.5rem;
  color: black;
}

.coordinates > h4 {
  color: #00134d;
  font-weight: 600;
}

.coordinates > h6 {
  color: black;
}

.connect {
  background-color: #00134d;
  margin-left: 60px;
  margin-right: 60px;
  padding: 20px;
  border-radius: 0 0 5px 5px;
}

.icons {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.callfor h5 {
  color: white;
}

.callable a i {
  color: white;
  font-size: 30px;
  margin: 0 10px;
}

.callable a i:hover {
  scale: 1.1;
  transition: 0.5s;
}
.description-container h3 {
  /* margin: 0 0 10px; */
  color: white;
  font-weight: bolder;
  position: relative;
  left: 250px;
  font-size: clamp(16px, 4vw, 20px);
  
}
@media (max-width: 768px) {
  .content {
    padding: 0;
  }

  .image-container {
    width: 25%;
  }

  .desc {
    flex-direction: column;
    text-align: center;
    /* width: 100%; */
    /* margin-top: 15px; */
    padding: 10px;
  }

  .text {
    width: 100%;
  }

  .text p {
    padding: 0;
  }
  .description-container h3 {
    left: initial;
    overflow: auto;
    word-wrap: break-word;
    text-align: center;
    padding-left: 150px;
    margin: 0;
   
  }
}

@media (max-width: 600px) {
  .content {
    padding: 0;
  }

  .image-container {
    width: 30%;
    top: 40px;
    left: 10px;
  }

  .description-container {
    margin-right: 10px;
    margin-left: 10px;
  }

  .description-container h3 {
    left: initial;
    overflow: auto;
    word-wrap: break-word;
    text-align: center;
    padding-left: 120px;
    margin: 0;
   
  }

  .desc {
    flex-direction: column;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
  }

  .text p {
    /* padding-top: 5px; */
    padding: 5px 0 0 0;
    font-size: 14px;
  }

  .connect {
    margin-left: 10px;
    margin-right: 10px;
  }

  .icons a i {
    font-size: 26px;
  }
}

@media (max-width: 425px) {
  .description-container h3 {
    margin-left: -45px;
  }
  .callfor h5{
    font-size: 16px;
    margin-top: 5px;
  }
  .callable a i{
    font-size: 24px;
    margin-left: 10px;
  }
  .icons {
    display: grid;
    grid-template-rows: 2fr; 
    gap: 10px; 
    align-items: center; 
    text-align: center;
  }
}