.modal-container {
  position: fixed;
  margin: 0;
  /* top: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 980;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal1 {
  height: 600px;
  width: 800px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #000;
  background-color: #fff;
}

.modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 40px;
  padding-left: 30px;
  color: #01134d;
  font-weight: 400;
}

.close-container {
  height: 40px;
  width: 40px;
  border: 2px solid red;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.close {
  color: #fff;
  font-size: 30px;
  text-align: center;
  font-weight: 700;
}

.close-container:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.5s;
  box-shadow: 1px 1px 10px #000;
}

.picsque {
  width: 50%;
  height: auto;
  border-radius: 5px;
}
.layout_outer_flex{
  display: flex;
  height: 90%;
  flex-direction: column;
  justify-content: space-evenly;
}

.first-flex {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.popcontent1 {
  padding: 5px;
  color: black;
  /* font-weight: 600; */
  line-height: 1.5rem;
  width: 48%;
  font-size: 18px;
  text-align: justify;
}

.popcontent2 {
  /* font-weight: 600; */
  line-height: 1.5rem;
  font-size: 18px;
  padding: 5px;
  color: black;
  text-align: justify;
}

.not_show{
    display: none;
}

.show_this{
    display: flex;
}

.colo {
  /* border: 3px solid black; */
  /* display: flex;
  justify-content: center; */
  /* display: inline-block; */
  margin-bottom: 50px ;
}

.contrib {
  padding: 20px;
}

.contr {
  width: 400px;
  height: 350px;
  background-color: #fff;
  border-radius: 5px;
  /* border: 1px solid #000; */
  box-shadow: 1.9px 1.9px 2.2px rgba(0, 0, 0, 0.07),
    4.7px 4.7px 5.3px rgba(0, 0, 0, 0.05), 8.8px 8.8px 10px rgba(0, 0, 0, 0.042),
    15.6px 15.6px 17.9px rgba(0, 0, 0, 0.035),
    29.2px 29.2px 33.4px rgba(0, 0, 0, 0.028),
    70px 70px 80px rgba(0, 0, 0, 0.02);
}

.cont-pic {
  width: 400px;
  border-radius: 5px 5px 0px 0px;
  height: 200px;
  /* box-shadow: 2px 2px 5px #000; */
}

.cont-desc {
  /* padding: 0 !important; */
  margin: 10px 10px;
  height: 100px;
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
}

.cont-head {
  font-size: 23px;
  font-weight: 400;
  color: #01134d;
}



.cont-abt {
  margin-top: 5px;
  /* background-color: #01134d; */
  text-align: justify;
  height: 50px;
  font-size: 17px;
  padding-left: 5px;
}

.but {
  border-radius: 5px;
  cursor: pointer;
  /* height: 40px; */
  /* width: 40px; */
  padding: 2px;
  display: flex;
  align-items: center;
  color: #fff;
  /* border: 2px solid #01134d; */
  text-decoration: none;
  background-color: #01134d;

}
.but:hover{
  background-color: #ff6600;
  color: #00134d ;
}


.yah {
  font-size: 24px;
  color: #fff;
}

.but:hover .yah {
  transform: scale(1.1);
  transition: 0.5s;
}

.but:hover {
  transform: scale(1.1);
  box-shadow:
  5px 5px 2.1px rgba(0, 0, 0, 0.06),
  10px 10px 8.4px rgba(0, 0, 0, 0.067),
  14px 14px 20.5px rgba(0, 0, 0, 0.067),
  17.2px 17.2px 40.3px rgba(0, 0, 0, 0.064),
  21px 21px 64.3px rgba(0, 0, 0, 0.061),
  25px 25px 80px rgba(0, 0, 0, 0.07)
;
  transition: 0.5s;
}

.mod{
  animation: fade_in;
  animation-duration: 4s;
}



.modal_ext_link_holder{
  
  display: flex;
  justify-content: center;
}

.modal_ext_link_tag{
  border-radius: 5px;
  cursor: pointer;
  /* height: 40px; */
  /* width: 40px; */
  padding: 7px;
  display: flex;
  align-items: center;
  color: #fff;
  border: 2px solid #01134d;
  text-decoration: none;
  background-color: #01134d;
}



.modal_ext_link_tag:hover{
  color: #fff;
  transform: scale(1.1);
  box-shadow:
  5px 5px 2.1px rgba(0, 0, 0, 0.06),
  10px 10px 8.4px rgba(0, 0, 0, 0.067),
  14px 14px 20.5px rgba(0, 0, 0, 0.067),
  17.2px 17.2px 40.3px rgba(0, 0, 0, 0.064),
  21px 21px 64.3px rgba(0, 0, 0, 0.061),
  25px 25px 80px rgba(0, 0, 0, 0.07)
;
  transition: 0.5s;
}


@keyframes fade_in {
  from {
      opacity: .4
  }
  to {
      opacity: 1
  }
}

@media (max-width: 1300px) {
  @media (min-width: 1100px) {
    .contr {
      width: 300px;
      height: 263px;
    }
    .cont-pic {
      width: 300px;
      height: 150px;
    }
    .cont-desc {
        margin: 5px;
      padding: 0 !important;
      height: 75px;
    }
    .cont-head {
      font-size: 20px;
    }
    .cont-abt {
      height: 37.5px;
      font-size: 16px;
    }
    .but {
      font-size: 16px;
      height: 35px;
      /* width: 35px; */
    }
    .yah {
      font-size: 20px;
    }
  }
}

@media (max-width: 860px) {
    .cont-pic {
        width: 350px;
        height: 175px;
      }
    .cont-desc {
        margin-top: 20px;
      padding: 0 !important;
      /* justify-content: center; */
      height: 75px;
    }
    .contr {
        width: 350px;
        height: 307px;
      }
      .cont-head{
        font-size: 18px;
      }
      .cont-abt{
        font-size: 16px;
      }
      .but {
        font-size: 14px;
        height: 25px;
        /* width: 35px; */
      }
  .modal1 {
    height: 450px;
    width: 600px;
    padding: 15px;
  }
  .modal-title {
    font-size: 30px;
    padding-left: 30px;
  }
  .close-container {
    height: 30px;
    width: 30px;
  }
  .close {
    font-size: 22.5px;
  }
  .popcontent1 {
    font-size: 14px;
    line-height: 18px;
  }
  .popcontent2 {
    line-height: 18px;
    font-size: 14px;
  }

  @media (max-width: 650px) {
    
    .cont-pic {
      width: 300px;
      height: 150px;
    }
    .cont-head {
      font-size: 20px;
    }
    .cont-abt {
      height: 37.5px;
      font-size: 16px;
    }
    .cont-desc {
        margin: 5px;
      padding: 0 !important;
      height: 115px;
      justify-content: space-between;
    }
    .contr {
        width: 300px;
        height: 283px;
      }
      .but {
        font-size: 16px;
        height: 35px;
        /* width: 35px; */
      }
    .yah {
      font-size: 20px;
    }
  }
}

@media (max-width: 630px) {
  .modal1 {
    height: auto;
    width: 400px;
    padding: 15px;
  }
  .modal-title {
    font-size: 24px;
    padding-left: 10px;
  }
  .close-container {
    height: 30px;
    width: 30px;
  }
  .close {
    font-size: 22.5px;
  }
  .first-flex {
    display: block;
  }
  .picsque {
    width: 100%;
  }
  .popcontent1 {
    width: 100%;
    padding-top: 20px;
    font-size: 14px;
    line-height: 18px;
  }
  .popcontent2 {
    line-height: 18px;
    font-size: 14px;
  }
}

@media (max-width: 450px) {
    .contr {
        width: 300px;
        height: 283px;
      }
  .cont-pic {
    width: 300px;
    height: 150px;
  }
  .cont-desc {
    padding: 10px 10px;
    height: 115px;
  }
  .cont-head {
    font-size: 20px;
  }
  .cont-abt {
    height: 37.5px;
    font-size: 16px;
  }
  .but {
    height: 35px;
    /* width: 35px; */
  }
  .yah {
    font-size: 20px;
  }
}

@media (max-width: 430px) {
  .modal1 {
    height: auto;
    width: 350px;
    padding: 10px;
  }
  .modal-title {
    font-size: 20px;
    padding-left: 10px;
  }
  .close-container {
    height: 26px;
    width: 26px;
  }
  .close {
    font-size: 20px;
  }
}

@media (max-width: 370px) {
  .but {
    /* display: none; */
  }
  .heads {
    font-size: 26px;
  }
}
