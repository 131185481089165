.iqac_com_whole{
    width: 75%;
    padding: 20px;
}
.iqac_com_content_card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    padding: 20px 0px;
    /* align-items: center; */

}

@media (max-width:768px) {
    .iqac_com_whole{
        width:100%;
    }
}