.sdgclub{
  padding-top: 30px;
  padding-left: 15px;
  justify-content: center;
  margin: 20px;
  
}
.sdg_acgcet{
    font-size: 22px;
    line-height: 1.9rem;
    text-align: justify;
}
.sdg_developement{
    font-size: 22px;
    line-height: 1.9rem;
    text-align: justify;
}
.sdg_img img,.sdg_img2 img{

    justify-content: center; /* Center horizontally */
    align-items: center;
    
    max-width: 100vw;
    width:900px;
    padding-bottom: 10px;
}
.sdg_img,
.sdg_img2 {
    /* padding-left: 200px/; */
    justify-content: center; /* Center horizontally */
    align-items: center;
    display: flex;

}
.sdg_dev1,.sdg_he_para,.sdgandaccet{
    
    font-size: 22px;
    line-height: 1.9rem;
    text-align: justify;
}
.sdg_dev1{
    font-size: 22px;
    line-height: 1.9rem;
    padding-top:17px;
    text-align: justify;
}
.sdgcards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
@media(max-width :768px){
    .sdg_img img,.sdg_img2 img{
      width: 600px;

padding-left: 50px;
       
    }
}
@media(max-width : 500px){
    /* .sdgclub{
        font-size: 15px;
    } */
    .sdg_img img,.sdg_img2 img{
        /* justify-content: center;
        align-items: center; */
        width: 300px;

padding-left: 0px;
        padding-right: 5px;
    }
    /* .sdg_img,.sdg_img2 {
        padding-left: 40px;
    } */
    .sdgclub{
        padding: 0px;
        /* margin: 0px; */
    }
    .sdg_dev1,.sdg_dev1,.sdg_he_para,.sdgandaccet,.sdg_developement{
        font-size: 18px;
    }
    .sdg_heading  {
       
       
    }
    .sdg_heading1{
        font-size: 18px;
        padding-top: 20px;
    }
    .sdg_heading p{
        font-size: 18px;
    }
}