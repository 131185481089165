.dash_complete_div{
    width: 100%;
    display: flex;
  }

  .dash-sidenav{
    width: 20%;
    background-color: #00134d;
    /* height: 100%; */
    /* position: fixed; */
    /* top: 100; */
    /* background-size: 100% 102%; */
    padding-top: 40px;
    margin: 0;
    overflow: auto;
}

.dash-side-nav-items{
    /* margin-top: 30px; */
    display: block;
  color: white;
  padding: 16px;
  text-decoration: none;
  text-align: center;
  letter-spacing: 2px;
    /* margin-top: 20px; */
}
.dash-side-nav-items:hover{
    /* background-color: grey; */
    color: #ff6600;
    border-radius: 20px 0px 0px 20px;
  width: 80%;
  margin-left: 59px;
  font-weight: 600;
  text-decoration: none;
}

.dash-side-nav-items{
    margin-top: 30px;
    font-size: 25px;
    text-decoration: none;
    font-size: 600;
}
.active{
    background-color: white;
  color: black;
  border-radius: 20px 0px 0px 20px;
  width: 90%;
  margin-left: 29px;
  font-weight: 600;
  text-decoration: none;
}
@media(max-width:800px){
    .dash-sidenav{
      display: none;
    }
}

@media (max-width:500px){
    .dash-sidenav{
        display: none;
    }
}

