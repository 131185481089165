.whole_cont{
    margin-top: 30px;
    padding: 20px;
    /* height: 500px; */
}

.abt_heading{
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #00134d;
}

.sect{
    padding: 20px;
    width: 78%;
    /* background-color: antiquewhite; */
}

.div_heading{
    font-size: 26px;
    color: #00134d;
    /* font-weight: 300; */
}

.visit_content{
    padding: 10px 30px;
}

.abt-sub-head{
    /* padding: 30px; */
    margin-bottom: 10px;
    font-size: 22px;
    color: #00134d;
}

.visit_transport{
    font-size: 30px;
    color: #00134d;
}

.div_content{
    padding: 15px;
}
.about_content{
    font-size: 18px;
    line-height: 1.5rem;
    color: black;
    text-align: justify;
}
.big_text{
    color: black;
        margin-left: 30px;
        font-size: 28px;
        /* font-weight: bold; */
}

.flex_1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex_1 p{
    width: 50%;
}
.flex_3{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
 
}
.flex_3 p{
    width: 50%;
}
.flex_2{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}
.flex_2_div{
    width:50%
}
.img_holder{
    width: 48%;
    margin-bottom: 20px;
    /* background-color: antiquewhite; */
}
.flex_img{
    width: 98%;
    height: auto;
    /* max-height: 300px; */
}
.responsive_text{
    display: none;
}
.map{
    height: auto;
    width: 100%;
}
.key_title{
    color: #00134d;
    font-size: 22px;
}
.keys_holder{
    display: flex;
    flex-wrap: wrap;
}
.campus_map_key{
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
}

@media(max-width:1145px){
    .responsive_text{
        display: block;
    }
    .lap_text{
        display: none;
    }
}
/* @media(max-width:1100px){
    .sect{
        width: 100%;
    }
    .content{
        margin-bottom: 10px;
    }
} */


@media(max-width:1000px){
    .flex_1{
        flex-direction: column;
       
    }
    .flex_1 p{
        width:100%;
    }
    .flex_3{
        flex-direction: column-reverse;

    }
    .flex_3 p{
        width:100%;
    }
    .img_holder{
        width: 100%;
    }
    .flex_2{
        flex-direction: column;
       
    }
    .flex_2_div{
        width:100%;
    }
}

@media(max-width:768px){
    .sect{
        width: 100%;
    }
    .content{
        margin-bottom: 10px;
    }
}
@media(max-width:500px){
    .sect{
        padding: 20px 0;
    }
    .div_content{
        padding: 15px 5px;
    }
    .content{
        font-size: 16px;
    }
    .abt_heading{
        font-size: 28px;
    }
    .keys_holder{
        flex-direction: column;
        flex-wrap: nowrap;
    }
}


@media(max-width:321px){
    .abt_heading{
        font-size: 26px;
    }
}
