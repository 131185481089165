.antenna_flex1
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.flex1_content_holder{
    width: 50%;
}
.antenna_content{
    font-size: 18px;
    line-height: 1.5rem;
    color: black;
    text-align: justify;
}
.antenna_second_content{
    display: none;
    margin-bottom: 30px;
}
.antenna_flex1_img
{
    width:45%;
    margin-bottom: 30px;
}
.ant_img
{
    width: 100%;
    height: auto;
}
.antenna_list_content
{
    padding: 10px 20px;
}
.antenna_head{
    font-size: 24px;
    color: #00134d !important;
}
.antenna_registration_forms{
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin-bottom: 30px;
}
.antenna_forms{
    padding: 10px;
    /* text-decoration: none; */
    color: black;
}
.antenna_list_head{
    color: black;
    font-size: 20px;
}

@media(max-width:1100px){
    .break_content{
        display: none;
    }
    .antenna_second_content{
        display: block;
    }
}
@media(max-width:900px){
    .antenna_flex1{
        flex-direction: column-reverse;
    }
    .flex1_content_holder{
        width: 100%;
    }
    .antenna_flex1_img{
        width: 100%;
    }
}
@media(max-width:500px){
    .antenna_registration_forms{
        padding: 0;
    }
    .antenna_list_content{
        padding: 10px;
    }
    .antenna_list_head{
        font-size: 18px;
        font-weight: 700;
    }
    .antenna_head{
        font-size: 20px !important;
        font-weight: 900 !important;
        margin-bottom: 10px;
    }
    .antenna_content{
        font-size: 16px;
    }
}