.ubaall {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 20px;
}

.ubaabt {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  
  
}
.ubaabt1 {
  width: 70%;
  
}
.ubaabt1p{
  padding: 10px;
  text-align: justify;
  color: black;
  line-height: 1.5rem;
  font-size: 18px;
}
.ubaabt2 {
  width: 30%;
  
}
.ubaabt2i {
  width: 100%;
  height: auto;
}

.ubaaward {
  display: flex;
  justify-content: center;
  
}
.ubaawardi {
  padding: 5px;
  box-shadow: 1.9px 1.9px 2.2px rgba(0, 0, 0, 0.07),
    4.7px 4.7px 5.3px rgba(0, 0, 0, 0.05),
    8.8px 8.8px 10px rgba(0, 0, 0, 0.042),
    15.6px 15.6px 17.9px rgba(0, 0, 0, 0.035),
    29.2px 29.2px 33.4px rgba(0, 0, 0, 0.028),
    70px 70px 80px rgba(0, 0, 0, 0.02);
  background-color: #f1f1f1;
  border: 3px solid #00134d;
  width: 40%;
  height: auto;
}

@media (max-width: 1000px) {
  .ubaabt {
    flex-direction: column-reverse;

  }
  .ubaabt1 {
    width: 100%;
  }
      
  .ubaabt2 {
    width: 40%;
    margin-bottom: 10px;
  }
}

@media (max-width:768px){
    .ubaawardi{
            width: 50%;
        }
    
}

@media (max-width:600px){
    .ubaawardi{
      width: 100%;
  }
}

@media (max-width: 550px) {
  
  .ubaabt1p{
    padding: 0;
    font-size: 16px;
  }
  .ubaabt2 {
    width: 100%;
  }

}
