.proc_head{
    color:#00134d;
    font-weight:500;
    font-size: 26px;
    margin:60px 0px 20px 40px;
}
.proc_tab{
    width:70%;
    margin:50px 0px 50px 50px;  
}

@media(max-width:600px) {
    .proc_tot{
        width:90%;
        margin: 35px auto 10px auto;
    }
    .proc_head{
        margin:20px 0px 20px 0px;
        text-align: center;
    }
    .proc_tab{
        width:90%;
        overflow-x: scroll;
        margin:  30px auto 10px auto;
    }
}

@media (max-width:768px) {
    .proc_tot{
        width:90%;
        margin: 50px auto 10px auto;
    }
    .proc_head{
        margin:20px 0px 20px 0px;
        text-align: center;
    }
    .proc_tab{
        width:100%;
        margin: 30px auto 10px auto;
    }
}