.blogs {
  /* margin-top: 25px; */
  margin-bottom: 50px;
  /* padding: 20px; */
  background-color: #ffffff;
  color: #00134d;
  /* background-color: aqua; */
}

.news_heading{
  padding: 20px;
}