.audit_container{
    /* margin-top: 30px; */
    padding: 20px;
}
.audit_stmt_sec{
    display: flex;
    justify-content: center;
    align-items: center;
}
.audit_stmt_recs{
    width:70%
}



@media(max-width:1024px){
    .audit_stmt_recs{
        width: 100%;
    }
}
@media(max-width:768px){
    .audit_stmt_recs{
        width:70%
    }
}

@media(max-width:500px){
    .audit_stmt_recs{
        width: 100%;
    }
}
