.parallax {
    background-image: url("../../assets/pictures/prefooter.webp");
    width: 100%;
    height: 650px;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #00134d;
    position: relative;
    /* z-index: -1; */
}

.dna{
    height: 650px;
    opacity: 0.75;
    background-Color: #00134d; 
    font-size: 20px 
}

.figure_box{
    margin:40px 30px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.figure-text {
    color: white;
    font: "futura-pt-bold", sans-serif;
    font-size: 24px;
}

.figure {
    font-family: 'Times New Roman', serif;
    font-weight: normal;
    font-size: 40px;
    color: #ff6600;
    text-shadow: 0 2px 4px #000000;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;
}



.ri {
    height: 90px;
}
.boxy{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.boxy-1{
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
.ther_gap{
    height: 60px;
}

.no {
    align-items: center;
    color: #ff6600;
}

.ther {
    /* display: flex; */
    justify-content: space-evenly;
    /* background-color: #ff6600; */
    /* margin: 40px 0; */
}

.jack {
    color: #ff6600;
    /* border-bottom: 2px solid #ff6600; */
    font-weight: bolder;
}

.jack:hover {
    color: #00134d;
    background-color: #ff6600;
    /* border: 2px solid #ff6600; */
}

.fs {
    margin-top: 40px;
    color: white;
    font-size: 27px;
    text-align: center;
    font-family: 'Times New Roman', serif;
}

.d-grip {
    margin-top: 40px;
}

.section {
    z-index: 1;
}

.headfoot {
    padding-top: 30px;
    padding-bottom: 20px;
    /* background-color: #ff6600; */
    color:white;
    text-align:center; 
    font-weight: 600;
    font-size:xx-large;
    font-family: "'Times New Roman', serif";
    text-shadow: 2px 2px 4px #000000
}

@media(max-width:1293px){
    .figure_box{
        margin:40px 20px;
    }
}
@media(max-width:1200px){
    .ther_gap{
        height: 30px;
    }
    .figure_box{
        margin: 30px 20px;
    }
}

@media(max-width:872px){
    .boxy-1{
        grid-template-columns: repeat(3,1fr);
    }
}

@media (max-width:800px) {
    .parallax{
        height: 620px;
    }
    .dna{
        height: 620px;
    }
    .ther_gap{
        height: 10px;
    }
    .ri {
        height: 0;
    }
    .dna {
        padding: 0 30px;
    }
    .headfoot {
        padding-top: 60px;
    }
    .figure {
        font-size: 30px;
    }
    .figure-text {
        font-size: 28px;
    }
    .figure_box {
        margin: 20px 15px;
    }
    .fs {
        margin-top: 20px;
        font-size: 20px !important;
    }
    .d-grip {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }
}

@media(max-width:723px){
    .figure-text{
        font-size: 20px;
    }
}

@media(max-width:575px) {
    .jack {
        font-size: 18px;
    }
    .boxy{
        justify-content: center;
    }
    .boxy-1{
        grid-template-columns: repeat(2,1fr);
    }
    .headfoot{
        padding-top: 30px;
    }
}

@media(max-width:450px) {
    /* .parallax{
        height: 750px;
    }
    .dna{
        height: 750px;
    } */
    .figure_box{
        /* margin: 10px; */
    }
    .figure{
        /* margin-bottom: 0; */
        /* font-size: 22px */
    }
    .jack {
        font-size: 14px;
    }
    .headfoot {
        font-size: 30px!important;
        padding-bottom: 0 !important;
    }
    /* .figure {
        font-size: 25px;
    }*/
    .figure-text {
        /* font-size: 22px; */
    }
    .boxy{
        flex-direction: column;
        align-items: center;
    }
    
    .ther_gap{
        height: 0px;
    }
    .headfoot{
        padding-top: 30px;
    }
    .dna{
        padding: 20px 10px;
    }
}

@media(max-width:356px){
    .headfoot{
        padding-top: 10px;
    }
    .figure-text{
        font-size: 17.5px;
    }
    .figure{
        font-size: 24px;
    }
}