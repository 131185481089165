.verify_entire{
    margin-top: 10px;
    padding:20px;
}

.verify_flex{
    display:flex;
    align-items: center;
}

.verify1{
    margin-top: 10px;
    margin-left: 70px;
}
.verify2{
    margin-top: 10px;
    margin-left: 350px;
}

.verify_head{
    margin-bottom: 10px;
    font-weight:600 ;
    color: #00134d;
}

.verify_table{
    margin-top: 25px;
}
.verify_table_head{
    font-size: 28px;
    margin-bottom: 20px;
    color: #00134d;
    text-align: center;
}

@media(max-width:700px){
    .verify_flex{
        flex-direction: column;
        align-items: start !important;
        justify-content: left !important;
      }
      .verify1{
        margin-left: 5px !important;
      }
      .verify2{
        margin-left: 5px !important;
      }
}

@media(max-width:480px){
    .verify_table{
        overflow-x: scroll;
    }
}
