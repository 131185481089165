.card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    margin: 20px;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  .card-content {
    text-align: center;
    margin: 10px 0;
  }
  
  .card-content h3 {
    margin: 0;
    font-size: 1.25em;
    color: #333;
  }
  
  .card-footer {
    padding: 15px;
    background: #f7f7f7;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .view-video-button {
    background-color:#00134d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.2s ease-in-out;
  }
  
  .view-video-button:hover {
    background-color: #0056b3;
  }
  