.iqac_stra_whole{
    width: 75%;
    padding: 20px;
}
.iqac_stra_content_card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    padding: 20px 0px;

}
.iqac_card_one{
    margin-bottom: 100px;
}

@media (max-width:768px) {
    .iqac_stra_whole{
        width:100%;
    }
}