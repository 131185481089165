.found {
    padding: 20px;
}

.foun {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #D9EDFF;
}

.founder {
    width: 60%;
}

.name {
    font-size: 30px;
    color: #00134d;
    font-weight: 700;
    margin-bottom: 30px;
}

.hl1 {
    height: 9px;
    background-color: white;
}

.founder-desc {
    font-size: 18px;
    color: black;
    /* font-weight: 500; */
    line-height: 1.5rem;
    /* color: white; */
    text-align: justify;
}

.founder-pic {
    width: auto;
    height: 50%;
}
.founder_know_more_holder{
    display: flex;
    justify-content: end;
}
.founder_know_more{
    margin: 0 10px;
    color: #4d4d4d;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    /* font-weight: 600; */
    padding: 5px;
}
.founder_know_more::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ff6600;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  .founder_know_more:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }

@media (max-width:700px) {
    .foun {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }
    .founder {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    /* .founder-desc {
        font-size: 13px;
        font-weight: 700;
    } */
    .name {
        margin-top: 10px;
        font-size: 20px;
    }
    .founder-pic {
        max-width: 160px;
    }
}

@media(max-width:500px){
    .founder-desc{
        font-size: 16px;
    }
}