.whole_content{
    /* margin-top: 30px; */
    padding: 20px;
}

.princ{
    padding: 20px;
    background-color: #D9EDFF;
    border-radius: 5px;
}

.heading1{
    color: #01134d;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    /* text-decoration: underline; */
}
.princ_msg_head{
    color: #01134d;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
}

.princ_msg_content{
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
}

.princ_msg_content_img{
    width: 35%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
}

.princ_msg_content_desig{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 20px 0px;
    >div{
        color: #01134d;
        font-weight: 600;
    }
}
.princ_msg_content_text{
    width: 55%;
    text-align: justify;
    color: black;
    line-height: 1.6rem;
    font-size: 18px;
    align-items: center;
}

.princ_msg_content_img_pic{
    width: 100%;
}

.princ_pic{
    width: 100%;
}
.admin_staff{
    /* padding: 20px; */
    margin-top: 60px;
}

@media(max-width:1023px){
    .princ_msg_content{
        flex-direction: column;
        align-items: center;
    }
    .princ_msg_content_img{
        width: 50%;
    }
    .princ_msg_content_text{
        width: 100%;
    }
}

@media(max-width:768px){
    .princ_msg_content_img{
        width: 70%;
    }
    .princ_msg_content_text{
        width: 100%;
    }
}

@media(max-width:475px){
    .princ_msg_content_text,.org_text{
        font-size: 14px;
    }
    .heading{
        font-size: 24px;
    }
    .princ_msg_head{
        font-size: 18px;
    }
    .princ_msg_content_desig{
        margin: 10px 0;
        >div{
            font-size: 16px;
        }
    }
    .princ,.org_structure{
        padding: 15px;
    }
    .princ_msg_head{
        margin-bottom: 10px;
    }
    .whole_content{
        margin-top: 70px;
        padding: 10px;
    }
}

@media(max-width:375px){
    .princ_msg_content_text,.org_text{
        font-size: 14px;
    }

    .princ_msg_content_desig{
        margin: 10px 0;
        >div{
            font-size: 14px;
        }
    }
}