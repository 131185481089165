.rti_content{
    margin-top: 20px;
    padding:10px;
}
.rti_cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 0px;
}
.rti_officer_holder{
  padding: 10px;
}
.rtiofficer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 20px;
  }
  .rtiofficer_bg{
    background-color: #d9edff;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
}
.rtiofficer_img{
  border-radius: 50%;
  width: 60%;
}
.rtiofficer_name,
.rtiofficer_designation,
.rtiofficer_bg :last-child {
  color: #000000;
  font-size: 1em;
  text-align: center;
}
.rtiofficer_name{
    margin-top: 10px;
}
@media (max-width: 520px){
  .rtiofficer_img{
    width: 70%;
  }
  .rtiofficer_bg{
    width: 250px;
  }
  .rtiofficer_des p{
    font-size: 0.7em;
    margin:5px;
  }
  .rtiofficer_des .rtiofficer_mail {
    font-size: 0.7em;
  }
}

