.content{
  margin-top: 20px;
  padding:10px;
}
.syllabus_card_holder{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding: 30px 0px; */
}
@media(max-width:500px){
  .syllabus_card_holder{
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
    
  }
 
  
  
}