* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.csebg {
  /* background: url("../../assets/pictures/csebg.webp"); */
  background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/dept_bgs/csebg.webp");
  min-height: 550px;
  background-color: #00134d;
  position: relative;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.cseheading {
  text-align: center;
  position: absolute;
  color: #fff;
  font-size: 250%;
  top: 48%;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 0px 0px 10px #000;
}
.cse_sub_heading {
  text-align: center;
  position: absolute;
  color: #ff6600;
  font-size: 150%;
  top: 75%;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 0px 0px 10px #000;
}
.csetwo-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.csedark {
  /* display: flex;
    flex-direction: column; */
  padding: 40px 20px;
  width: 75%;
  /* border-radius: 5px 0 5px 0; */
}
.cse_sub_head {
  font-size: 40px;
  font-weight: 500;
  /* width: 100%;  */
  /* text-align: left;  */
  color: #00134d;
}
.csetwo-col p {
  margin-top: 20px;
  width: 50%;
  padding: 0 30px;
}
.cse_dept_content {
  text-align: justify;
  font-size: 18px;
  line-height: 1.5rem;
  color: black;
}
.csetwo-col .dept_img_holder {
  width: 50%;
  /* height: auto; */
  /* align-items: center;
    justify-content: center; */
}
.dept_img {
  width: 100%;
  height: auto;
}
.csetwo-col1 p {
  width: 100%;
  padding: 20px 30px;
}
@media(max-width:1100px){
  .csetwo-col {
    
    flex-direction: column;
  }
  .csetwo-col p {
    width: 100%;
  }
  .csetwo-col .dept_img_holder {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  


}

@media (max-width: 1024px) {
  .cseheading {
    top: 35%;
    font-size: 150%;
    font-weight: 500;
  }
  .cse_sub_heading {
    font-size: 100%;
    font-weight: 500;
  }
  .csebg {
    width: 100%;
    height: 700px;
  }
}
@media (max-width: 768px){
    .csebg{
        width: 100%;
        height: 500px;
    }
    .csedark{
        width: 100%;
    }
    .csetwo-col{
        display: flex;
        flex-direction: column;
    }
    .csetwo-col p{
        width: 100%;
    }
    .csetwo-col .dept_img_holder{
        width: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
}
@media (max-width: 425px){
    .csebg{
        /* background: url("../../assets/pictures/csebgm.webp"); */
        background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/dept_bgs/csebgm.webp");
        min-height: 550px;
        width: 100%;
        height: 750px;
        background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .csetwo-col p {
    padding: 0 5px;
  }
  .csetwo-col1 p {
    padding: 0 5px;
  }
}

@media (max-width: 321px) {
  .cse_sub_head {
    font-size: 26px;
  }
}
