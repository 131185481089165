.ece_sub_heading {
    text-align: center;
    position: absolute;
    color: #ff6600;
    font-size: 150%;
    top: 75%;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-shadow: 0px 0px 10px #000;
  }
  .shengtwo-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .shengdark {
    /* display: flex;
      flex-direction: column; */
    padding: 40px 20px;
    width: 75%;
    /* border-radius: 5px 0 5px 0; */
  }
  .ece_sub_head {
    font-size: 40px;
    font-weight: 500;
    /* width: 100%;  */
    /* text-align: left;  */
    color: #00134d;
  }
  .shengtwo-col p {
    margin-top: 20px;
    width: 50%;
    padding: 0 30px;
  }
  
  .sheng_dept_content {
    text-align: justify;
    font-size: 18px;
    line-height: 1.5rem;
    color: black;
  }
  .shengtwo-col .dept_img_holder {
    width: 50%;
    /* height: auto; */
    /* align-items: center;
      justify-content: center; */
  }
  .dept_img {
    width: 100%;
    height: auto;
  }
  .shengtwo-col1 p {
    width: 100%;
    padding: 20px 30px;
  }
  .ece_progs{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .shengbg{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .uct_bg{
    /* background-image: url("../../assets/pictures/uct.png"); */
    height: auto;
    width: 100%;
}

.uct_bg_mob{
    height: auto;
    width: 100%;
    display: none;
}

  @media(max-width:1100px){
    .shengtwo-col {
      
      flex-direction: column;
    }
    .shengtwo-col p {
      width: 100%;
    }
    .shengtwo-col .dept_img_holder {
      width: 90%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1024px) {
    .eceheading {
      top: 35%;
      font-size: 150%;
      font-weight: 500;
    }
    .ece_sub_heading {
      font-size: 100%;
      font-weight: 500;
    }
    .ecebg {
      width: 100%;
      height: 700px;
    }
  }
  @media (max-width: 768px) {
    .ecebg {
      width: 100%;
      height: 500px;
    }
    .shengdark {
      width: 100%;
    }
    
  }

  @media(max-width:576px){
    .sh_eng{
      overflow-x: scroll;
    }
    .uct_bg{
      display: none;
  }
  .uct_bg_mob{
      display: block;
  }
  }
  
  @media (max-width: 500px) {
    .ece_sub_head {
      font-size: 28px;
    }
    .sheng_dept_content {
      font-size: 16px;
    }
    .shengdept_float_menu {
      display: none;
    }
    
    
  }
  
  @media (max-width: 425px) {
    
    .shengtwo-col p {
      padding: 0 5px;
    }
    .shengtwo-col1 p {
      padding: 0 5px;
    }
  }
  
  @media (max-width: 321px) {
    .ece_sub_head {
      font-size: 26px;
    }
  }
  