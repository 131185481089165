.comp_table{
   font-family: Arial, Helvetica, sans-serif;
   border-collapse: collapse;
   width: 100%;
}

.comp_td{
    border:1px #dddddd solid;
    text-align: left;
    padding: 20px;
    line-height: 1.5rem;
    color: black;
    white-space: pre-line;
}

.comp_th{
    border: 1px solid #dddddd;
    color: #00134d;
    padding: 20px;
    text-align: center;
}

.comp_tr:nth-child(even){
    background-color:#ECF6FF;
}

.comp_tr:nth-child(odd) {
    background-color:#D9EDFF;
}

.comp_td:nth-child(1){
    text-align: center;
}
