.visionchip {
  padding: 20px;
  font-size: 16px;
  margin-bottom: 30px;
  border-radius: 25px;
  background-color: #f1f1f1;
}

.visionchip h1 {
  color: #00134d;
  font-weight: 500;
}

.visionchip p {
  color: black;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5rem;
}

.visionhead {
  margin-bottom: 10px;
}

.visionlogosec {
  color: #00134d;
  font-size: 50px;
}

.visionlogo{
  fill: #00134d;
}

.vissionholder {
  display: flex;
  margin-left: 200px;
  justify-content: flex-end;
}

@media (max-width: 800px) {
  .vissionholder {
    margin-left: 150px;
  }
}

@media (max-width: 700px) {
  .visionlogosec {
    font-size: 35px;
  }
}

@media (max-width: 600px) {
  .vissionholder {
    margin-left: 0;
  }
}

@media(max-width:500px){
  .visionchip p{
    font-size: 16px;
  }
}