.hhoodd{
  margin-top: -30px;
  margin-bottom: 50px;
}
.hodmsg{
    width: 100%;
    background:#f1f1f1;
    border-radius: 5px;
    /* margin-left: 20px; */
    /* margin-top: 50px; */
    /* text-align: center; */
    box-shadow: 1.9px 1.9px 2.2px rgba(0, 0, 0, 0.07),
    4.7px 4.7px 5.3px rgba(0, 0, 0, 0.05), 8.8px 8.8px 10px rgba(0, 0, 0, 0.042),
    15.6px 15.6px 17.9px rgba(0, 0, 0, 0.035),
    29.2px 29.2px 33.4px rgba(0, 0, 0, 0.028),
    70px 70px 80px rgba(0, 0, 0, 0.02);
    
  }
  .hodmesg{
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba( 217, 237, 255, 0 );
    /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  
  .hoddiv_cont p{
    /* width: 90%; */
    margin-top: 30px;
    margin-bottom: 20px;
    /* margin-right: 10px; */
    text-align: justify;
    padding: 0 30px;
    font-size: 18px;
    line-height: 1.5rem;
    color:black;
  }
  
  .hodmesg .hoddp{
   width: 10%;
   height: auto;
  }
  
  .hodmesg .hoddp img{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);;
  }
  .hoddet1{
    display: none;
  }
  .hoddet{
    text-align: justify;
    /* padding: 0px 30px; */
    font-size: 18px;
    line-height: 1.5rem;
    background-color:#00134d;
    color: white;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-radius: 0 0 5px 5px;
    >h3,p{
      color: white;
    }
  }
  .hoddiv_cont{
    margin-top: 10px;
    margin-left:25px;
  }
  
  
  .quo img{
    position: relative;
    top: 40px;
    left: 30px;
    width:70px;
    height:70px;
    z-index: 1;
  }
  
  @media(max-width:1024px){
    .hodmesg .hoddp img{
        width: 250px;
        height: 250px;
        border-radius: 50%;
        margin-left:0;
        margin-top: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);;
    }
  
  }
  
 
  @media(max-width:768px){
    .hhoodd{
      margin-top: 100px;
    }
    .hodmsg{
        position: relative;
        padding: 16px;
        margin-top: 20px;
        /* width: 80%; */
        /* margin-left: 10%; */
        /* margin:56px 24px 0px 20px; */
        /* margin: 120px auto 20px auto; */
    }
    .hodmsg .hodmesg{
        width: 100%;
        flex-direction:column-reverse ;
        justify-content: center;
        padding: 0px 0px;
    }
    .hodmesg p{
        text-align: center;
    }
    .hoddet{
        display: none;
    }
    .hoddet1{
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 80px;
        margin-left: 290px;
        margin-bottom: 20px;
    }
    .hoddet1 .hoddet1h .hoddet1p{
      color:black;
    }
    .hodmesg .hoddp{
        /* width: 140px;
        height: 140px; */
        /* overflow: hidden; */
        position: absolute;
        top:10%;
        left: 15%;
        transform: translate(-50%,-50% );
        box-shadow:0 15px 35px -15px rgba(0 0 0 0.5),0 5px 20px 0 rgba(0 0 0 0.1);
    }
    .hodmesg .hoddp img{
        margin: 20px 0px 0px 0px;
        transform:scale(1.2);
        width:200px;
        height: 200px;
        
    }
   
  
    .hodcont{
        /* line-height: 24px; */
        width: 80%;
    }
    .hoddiv_cont p{
        /* margin-top: 30px;
        margin-bottom: 20px;
        margin-right: 0; */
        margin:0;
        margin-top: 40px;
        width: 100%;
        text-align: justify;
        /* padding: 0 30px; */
        font-size: 18px;
        padding: 0;
        line-height: 1.5rem;
        color:black;
    }
    
    .hoddiv_cont{
        margin-left: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
  
    .quo{
        
            display: none;
        
    }
  
  }
  
  @media (max-width:720px){
    .hodmesg .hoddp{
      left:10%;
    }
  
    .hoddet1{
      margin-left: 250px;
    }
  
    .hoddiv_cont p{
      margin-top: 20px;
    }
  }
  
  @media (max-width:666px){
    .hodmesg .hoddp img{
      width:180px;
      height:180px;
    }
  
    .hoddet1{
      margin-left: 200px;
      margin-top: 50px;
    }
  
    .hoddiv_cont p{
      margin-top: 25px;
    }
  }
  
  @media (max-width:625px){
    .hodmesg .hoddp img{
      width:150px;
      height: 150px;
    }
  
    .hoddet1{
      margin-left: 170px;
    }
    .hhoodd{
      margin-top: 30px;
    }
  }
  
  @media (max-width:580px){
    .hoddet1{
      margin-left: 180px;
    }
    .hodmsg{
      position: relative;
      padding: 10px;
      margin-top: 10px;
      width: 100%;
      /* margin-left: 5%; */
      /* margin:56px 24px 0px 20px; */
      /* margin: 120px auto 20px auto; */
  }
  .hhoodd{
    margin-top: 30px;
  }
  }
  
  @media (max-width:571px){
    .hodmesg .hoddp{
      top:5%;
      left:38%;
      /* right:60%; */
    }
    .hoddp .hoddpi{
      width: 170px;
      height: 170px;
    }
    .hoddet1{
      margin-top: 180px;
      margin-left: auto;
      margin-right: auto;

    }
    .hoddiv_cont p{
      margin-top: 0;
    }
    .hhoodd{
      margin-top: 60px;
    }
    /* .hodmsg{
      width: 90%;
      left: 0%;
    } */
  }
  @media (max-width:520px){
    .hodmesg .hoddp{
      top:5%;
      /* left:;x */
      margin-left:auto;
      margin-right:auto;
    }
    .hoddp .hoddpi{
      width: 150px;
      height: 150px;

      margin-left:auto;
      margin-right:auto;
    }
  }

  @media (max-width:495px){
    .hodmesg .hoddp{
      top:5%;
      left:40%;
      /* right:60%; */
      margin-left:auto;
      margin-right:auto;
    }
    .hoddp .hoddpi{
      width: 150px;
      height: 150px;
      margin-left:auto;
      margin-right:auto;
    }
  }
  @media (max-width:475px){
    .hodmesg .hoddp{
      top:5%;
      left:38%;
      /* right:60%; */
      margin-left:auto;
      margin-right:auto;
    }
    .hoddp .hoddpi{
      width: 150px;
      height: 150px;
      margin-left:auto;
      margin-right:auto;
    }
  }
  @media (max-width:450px){
    .hodmesg .hoddp{
      top:5%;
      left:36%;
      /* right:60%; */
      margin-left:auto;
      margin-right:auto;
    }
    .hoddp .hoddpi{
      width: 150px;
      height: 150px;
      margin-left:auto;
      margin-right:auto;
    }
    .hhoodd{
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  @media (max-width:426px){
    .hodmesg .hoddp{
      top:3%;
      left:37%;
      align-items: center;
      margin-left:auto;
      margin-right:auto;
    }
    .hoddp .hoddpi{
      align-items: center;
      justify-content: center;
      right:auto;
      left:auto;
      width: 130px;
      height: 130px;
      margin-left:auto;
      margin-right:auto;
    }
    .hoddet1{
      margin-top: 150px;
    }
  
  }

  @media (max-width:380px){
    .hodmesg .hoddp{
      top:3%;
      left:37%;
    }
    .hoddp .hoddpi{
      width: 115px;
      height: 115px;
      margin-left:auto;
      margin-right:auto;
    }
    .hoddet1{
      margin-top: 150px;
    }
  .hhoodd{
    margin-top: 30px;
    margin-bottom: 50px;
  }

}

@media (max-width:330px){
  .hodmesg .hoddp{
    top:3%;
    left:35%;
  }
  .hoddp .hoddpi{
    width: 115px;
    height: 115px;
    margin-left:auto;
    margin-right:auto;
  }
  .hoddet1{
    margin-top: 150px;
  }
.hhoodd{
  margin-top: 30px;
  margin-bottom: 50px;
}

}
  
  