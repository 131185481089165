.std_satisf_container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

  
  .std_satisf_form {
    display: flex;
    flex-direction: column;
  }
  
  .std_satisf_input, .std_satisf_select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .survey_question {
    margin-bottom: 15px;
  }
  .std_statisf_fb_subtitle {
    font-weight: bold;
    font-size: 20px;
    margin-top: 25px;
    text-align: center;
  }
  .std_statisf_fb_instruction{
    text-align: center;
    margin-bottom: 25px;
    margin-top:10px;
  }
  
  .radio_label {
    margin-right: 15px;
  }
  
  .std_satisf_submit {
    padding: 10px 20px;
    background-color: #00134d;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
  }
  
  @media (max-width: 600px) {
    .std_satisf_input, .std_satisf_select {
      width: 100%;
    }
  }
  