*{
  margin: 30px;
}

.supplier_fb_container {
max-width: 800px;

margin: 50px auto;
padding: 20px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
background-color: #fff;
}

.supplier_fb_form {
display: flex;
flex-direction: column;
}
.supplier_fb_error {
color: red;
font-size: 0.875rem;
margin-top: 4px;
}


.supplier_fb_row {
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
margin-bottom: 10px;
}

.supplier_fb_error {
color: red;
font-size: 14px;
margin-top: 5px;
}

.supplier_fb_input, .supplier_fb_select {
width: 48%;
padding: 15px;
margin-bottom: 10px;
border: 1px solid #ccc;
border-radius: 5px;
}

/* .supplier_fb_row_sl {
  width: 100%;
  margin-bottom: 20px; 
} */

.supplier_fb_subtitle {
font-weight: bold;
font-size: 20px;
margin-top: 25px;
text-align: center;
}

.supplier_fb_instruction{
  text-align: center;
  margin-bottom: 15px;
  margin-top: -10px;
}

.supplier_fb_feedback {
margin-top: 30px;
}

.supplier_fb_question {
margin-bottom: 20px;
}

.supplier_fb_ratings {
display: flex;
justify-content: space-around;
margin-top: 10px;
padding: 5px;
}

.supplier_fb_ratings label {
display: flex;
align-items: center;
}

.supplier_fb_ratings input {
margin-right: 5px;
}

.supplier_fb_ratings {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding: 5px;
}

.supplier_fb_ratings label {
  display: flex;
  align-items: center;
}

.sup_fb_btn{
  cursor: pointer;
}

.custom-radio {
  display: inline-block;
  width: 20px; /* Adjust the outer circle size */
  height: 20px;
  border: 2px solid #00134d;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
  text-align: center;
  line-height: 18px; /* Adjust to center the number vertically */
  font-size: 12px; /* Font size for the number */
  color: #00134d;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
}

input[type="radio"]:checked + .custom-radio {
  background-color: #00134d;
  color: white; /* Change number color when selected */
}

input[type="radio"] {
  display: none;
}

label {
  display: flex;
  align-items: center;
}

.supplier_fb_btn{
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
.supplier_fb_row {
  flex-direction: column;
}

.supplier_fb_input, .supplier_fb_select {
  width: 100%;
}
}