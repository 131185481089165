.forgetpass_div{
    margin-bottom: 200px;
    margin-top: 100px;
    text-align: center;
    
}
.password_regno{
    margin-top: 20px;
}
.forgetpassword button{
   margin-left: 20px;
    
}
input{
    padding: 10px;
}