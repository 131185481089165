.sports_cards_holder{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 30px 0;
}

.sports_content_area{
  padding: 20px;
}

.sports_head{
  margin: 20px 0;
  text-align: center;
  font-size: 40px;
    color: #01134d;
    font-weight: 700;
}

.sports_top_head{
  margin: 20px 0;
  text-align: center;
  font-size: 44px;
    color: #01134d;
    font-weight: 700;
}

.sports_notification{
  text-align: center;
  font-size: 18px;
}

.sports_page_anamoly{
  margin-top: 50px;
  margin-bottom: -40px;
}

.sports_insta_link{
  display: flex;
  gap: 10px;
  text-decoration: none;
  justify-content: center;
}

.sports_insta_desc{
  color: #01134d;
  text-align: center;
  text-decoration: underline;
  font-size: 18px
}

@media(max-width:500px){
  .sports_top_head{
    font-size: 28px;
  }

  .sports_head{
    font-size: 24px;
  }

  .sports_page_anamoly{
    margin-bottom: -50px;
  }
}


