*{
  margin: 30px;
}

.employer_fb_container {
max-width: 800px;

margin: 50px auto;
padding: 20px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
background-color: #fff;
}

.employer_fb_form {
display: flex;
flex-direction: column;
}

.employer_fb_row {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-bottom: 1em;
}

.employer_fb_input {
flex: 1;
width: 49%;
margin-right: 10px;
padding: 8px;
}

.employer_fb_input:last-child {
margin-right: 0;
}

.employer_fb_input_full {
width: 100%;
padding: 15px;
border: 1px solid #ccc;
border-radius: 4px;
}

/* Employer_fb.css */
.employer_fb_input, 
.employer_fb_select, 
.employer_fb_textarea {
  width: 100%;
  padding: 15px; /* Adjust this value for larger padding */
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.employer_fb_row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between the input fields */
}

.employer_fb_container {
  padding: 20px; /* Adjust for container padding */
}

.employer_fb_btn {
  text-align: center;
  margin-top: 20px;
}



.employer_fb_row_sl input{
  width: 100%;
  margin-bottom: 20px;
}

.employer_fb_subtitle {
font-weight: bold;
font-size: 20px;
margin-top: 25px;
text-align: center;
}

.employer_fb_instruction{
  text-align: center;
  margin-bottom: 15px;
  margin-top: 5px;
}
.employer_fb_row {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}




.employer_fb_feedback {
margin-top: 30px;
}

.employer_fb_question {
margin-bottom: 20px;
}

.employer_fb_ratings {
display: flex;
justify-content: space-around;
margin-top: 10px;
padding: 5px;
}

.employer_fb_ratings label {
display: flex;
align-items: center;
}

.employer_fb_ratings input {
margin-right: 5px;
}

.employer_fb_ratings {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding: 5px;
}

.employer_fb_ratings label {
  display: flex;
  align-items: center;
}

.custom-radio {
  display: inline-block;
  width: 20px; /* Adjust the outer circle size */
  height: 20px;
  border: 2px solid #00134d;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
  text-align: center;
  line-height: 18px; /* Adjust to center the number vertically */
  font-size: 12px; /* Font size for the number */
  color: #00134d;
  background-color: white;
  font-weight: bold;
}

input[type="radio"]:checked + .custom-radio {
  background-color: #00134d;
  color: white; /* Change number color when selected */
}

input[type="radio"] {
  display: none;
}

label {
  display: flex;
  align-items: center;
}

.employer_fb_btn{
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.employer_fb_error {
color: red;
font-size: 14px;
margin-top: 5px;
}
.employer_year_of_joining_container{
  color: red;
font-size: 14px;
margin-top: 5px;
margin-left:51%;
}
@media (max-width: 600px) {
.employer_fb_row {
  flex-direction: column;
}

.employer_fb_input, .employer_fb_select {
  width: 100%;

}

}
@media (max-width:600px) {
.employer_year_of_joining_container{
  color: red;
font-size: 14px;
margin-top: 5px;
margin-left: 0px;
margin-right: 40%;

}
}
