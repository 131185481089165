/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #00134d;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline_container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.timeline_content p {
  color: black;
  font-size: 18px;
  line-height: 1.5rem;
  text-align: justify;
}

/* The circles on the timeline */
.timeline_container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: #d9edff;
  border: 4px solid #00134d;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #d9edff;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #d9edff;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #d9edff;
  border-width: 10px 10px 10px 0;
  border-color: transparent #d9edff transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* The actual content */
.timeline_content {
  padding: 20px 30px;
  background-color: #d9edff;
  position: relative;
  border-radius: 6px;
  > h2 {
    color: #00134d;
  }
}

.button_holder{
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeline_link{
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  background-color: #00134d;
  border-radius: 5px;
  z-index: 2;
  position: relative;
  >span{
    color: #FFF;
  }
}
.timeline_link:hover{
  color: #FFF;
  transform: scale(1.05);
  transition: 0.5s ease;
  box-shadow: 1.9px 1.9px 2.2px rgba(0, 0, 0, 0.07),
    4.7px 4.7px 5.3px rgba(0, 0, 0, 0.05), 8.8px 8.8px 10px rgba(0, 0, 0, 0.042),
    15.6px 15.6px 17.9px rgba(0, 0, 0, 0.035),
    29.2px 29.2px 33.4px rgba(0, 0, 0, 0.028),
    70px 70px 80px rgba(0, 0, 0, 0.02);
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media (max-width: 768px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline_container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline_container::before {
    left: 60px;
    border: medium solid #d9edff;
    border-width: 10px 10px 10px 0;
    border-color: transparent #d9edff transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 18px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

@media (max-width:600px){
  .timeline_container{
    padding-left: 50px;
    padding-right: 10px;
  }

  .timeline_container::before{
    left: 43px;
  }
  .timeline_link{
    font-size: 16px;
    padding: 7px;
  }
}

@media(max-width:500px){
  .timeline_content p{
    font-size: 16px;
  }
}
