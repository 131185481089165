.placement-gallery-container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 2rem 2rem;
  font-size: 10px; /* Directly specify the font size */
}

.placement-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -1rem;
}

.placement-gallery-item {
  flex: 1 0 24rem;
  margin: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.placement-gallery-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms ease-out;
}

.placement-gallery-image:hover {
  transform: scale(1.15);
}

@supports (display: grid) {
  .placement-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin: 0;
  }
  @media (max-width: 768px) {
    .placement-gallery {
      grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    }
  }

@media (max-width: 576px) {
  .placement-gallery {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  }
}

@media (max-width: 480px) {
  .placement-gallery {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }
}}