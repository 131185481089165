.facility_holder{
    padding: 20px;
}
.facility_grid_holder{
    margin: 30px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(6,1fr);
}
.facility_crd_content{
    padding: 20px;
}

.facility_text_1,.facility_text_5,.facility_text_8,.facility_text_13{
    color: #00134d;
}
.facility_text_2,.facility_text_4,.facility_text_6,.facility_text_10,.facility_text_12{
    color: black;
}
.facility_text_3,.facility_text_7,.facility_text_9,.facility_text_11{
    color: #e5e4e0;
    /* color: white; */
}

.read_link_1{
    color: #00134d;
}

.read_link_1:hover{
    color: #00134d;
}
.read_link_2{
    color: #000;
}

.read_link_2:hover{
    color: #000;
}
.read_link_7,.read_link_9{
    color: #e5e4e0;
}

.read_link_7:hover,.read_link_9:hover{
    color: #e5e4e0;
}

.facility_head{
    font-size: 32px;
    /* color: black; */
    margin-bottom: 20px;
}

.facility_crd_writing{
    font-size: 18px;
    /* color: black; */
    text-align: justify;
    line-height: 1.5rem;
    text-indent: 20px;
}

.facility_image{
    width: 100%;
    height: auto;
}
.facility_item1{
    background-color: #d9edff;
    grid-column: span 2;
    grid-row: span 1;
}
.facility_item2{
    background-color: #e5e4e0;
    grid-column: span 4;
    grid-row: span 2;
}
.facility_item3{
    background-color: #00134d;
    grid-column: span 2;
    grid-row: span 2;
}
.facility_item4{
    background-color: #e5e4e0;
    grid-column: span 2;
    grid-row: span 1;
}
.facility_item5{
    background-color: #d9edff;
    grid-column: span 2;
    grid-row: span 1;
}
.facility_item6{
    background-color: #e5e4e0;
    grid-column: span 4;
    grid-row: span 1;
}
.facility_item7{
    background-color: #00134d;
    grid-column: span 2;
    grid-row: span 1;
}
.facility_item8{
    background-color: #d9edff;
    grid-column: span 1;
    grid-row: span 2;
}
.facility_item9{
    background-color: #00134d;
    grid-column: span 3;
    grid-row: span 2;
}
.facility_item10{
    background-color: #e5e4e0;
    grid-column: span 2;
    grid-row: span 1;
}
.facility_item11{
    background-color: #00134d;
    grid-column: span 2;
    grid-row: span 3;
}
.facility_item12{
    background-color: #e5e4e0;
    grid-column: span 2;
    grid-row: span 2;
}
.facility_item13{
    background-color: #d9edff;
    grid-column: span 2;
    grid-row: span 2;
}

.facility_read_more{
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.facility_read_link{
    /* color: black; */
    text-decoration: none;
    position: relative;
    overflow: hidden;
    font-weight: 600;
    padding: 5px;
}
.facility_read_link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ff6600;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  .facility_read_link:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }

  .facility_extra_text13{
    display: none;
  }

  .staff_quart_text_1260{
    display: none;
  }
  .library_text_980,.zonal_text_1440,.zonal_text_980{
    color: #e5e4e0;
  }
  .zonal_text_980{
    display: none;
  }

  @media(max-width:1260px){
    .facility_item6,.facility_item7{
        grid-column: span 3;
    }
    .facility_extra_text13{
        color: #00134d;
        display:contents;
    }
    .staff_quart_text_1440{
        display: none;
    }
    .staff_quart_text_1260{
        display: contents;
    }
  }

  @media(max-width:980px){
    .library_text_980{
        display: none;
    }
  }
  @media(max-width:960px){
    .zonal_text_1440{
        display: none;
    }
    .zonal_text_980{
        display: contents;
    }
  }
  @media(max-width:840px){
    .facility_item11{
        grid-column: span 3;
    }
    .facility_item10{
        grid-row:  span 2;
    }
    .facility_item12{
        grid-column: span 3;
        grid-row:  span 1;
    }
    .facility_item13{
        grid-column: span 3;
    }
    .facility_extra_text13{
        display: none;
    }
    .staff_quart_text_1440{
        display: contents;
    }
    .staff_quart_text_1260{
        display: none;
    }
    .zonal_text_1440{
        display: contents;
    }
    .zonal_text_980{
        display: none;
    }
  }

@media(max-width:750px){
    .facility_item1,
    .facility_item2,
    .facility_item3,
    .facility_item4,
    .facility_item5,
    .facility_item6,
    .facility_item7,
    .facility_item8,
    .facility_item9,
    .facility_item10,
    .facility_item11,
    .facility_item12,
    .facility_item13{
        grid-column: span 6;
    }

    .facility_holder{
        padding: 20px;
        margin-top: 20px;
    }
    .facility_grid_holder{
        margin: 10px;
    }
}

@media(max-width:350px){
    .facility_holder{
        padding: 10px;
    }
}