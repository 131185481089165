* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
.civil_study_mat_cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 0px;
}