* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.mechbg {
  /* background: url("../../assets/pictures/mechbg-tinted.webp"); */
  background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/dept_bgs/mechbg-tinted.webp");
  min-height: 550px;
  background-color: #00134d;
  position: relative;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.mechheading {
  text-align: center;
  position: absolute;
  color: #fff;
  font-size: 250%;
  top: 48%;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 0px 0px 10px #000;
}
.mech_sub_heading {
  text-align: center;
  position: absolute;
  color: #ff6600;
  font-size: 150%;
  top: 75%;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 0px 0px 10px #000;
}
.mechtwo-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mechdark {
  /* display: flex;
    flex-direction: column; */
  padding: 40px 20px;
  width: 75%;
  /* border-radius: 5px 0 5px 0; */
}
.mech_sub_head {
  font-size: 40px;
  font-weight: 500;
  /* width: 100%;  */
  /* text-align: left;  */
  color: #00134d;
}
.mechtwo-col p {
  margin-top: 20px;
  width: 50%;
  padding: 0 30px;
}
.mech_dept_content {
  text-align: justify;
  font-size: 18px;
  line-height: 1.5rem;
  color: black;
}
.mechtwo-col .dept_img_holder {
  width: 50%;
  /* height: auto; */
  /* align-items: center;
    justify-content: center; */
}
.dept_img {
  width: 100%;
  height: auto;
}
.mechtwo-col1 p {
  width: 100%;
  padding: 20px 30px;
}
.mech_progs{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media(max-width:1100px){
  .mechtwo-col {
    
    flex-direction: column;
  }
  .mechtwo-col p {
    width: 100%;
  }
  .mechtwo-col .dept_img_holder {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .mechheading {
    top: 35%;
    font-size: 150%;
    font-weight: 500;
  }
  .mech_sub_heading {
    font-size: 100%;
    font-weight: 500;
  }
  .mechbg {
    width: 100%;
    height: 700px;
  }
}
@media (max-width: 768px) {
  .mechbg {
    width: 100%;
    height: 500px;
  }
  .mechdark {
    width: 100%;
  }
  
}

@media (max-width: 500px) {
  .mech_sub_head {
    font-size: 28px;
  }
  .mech_dept_content {
    font-size: 16px;
  }
  .dept_float_menu {
    display: none;
  }
}

@media (max-width: 425px) {
  .mechbg {
    /* background: url("../../assets/pictures/mechbgm-tinted.webp"); */
    background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/dept_bgs/mechbgm-tinted.webp");
    min-height: 550px;
    width: 100%;
    height: 750px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .mechtwo-col p {
    padding: 0 5px;
  }
  .mechtwo-col1 p {
    padding: 0 5px;
  }
}

@media (max-width: 321px) {
  .mech_sub_head {
    font-size: 26px;
  }
}
