.annual_container{
    /* margin-top: 30px; */
    padding: 20px;
}
.annual_reports_container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.annual_reports{
    width: 70%;
}

@media(max-width:1024px){
    .annual_reports{
        width: 100%;
    }
}
@media(max-width:768px){
    .annual_reports{
        width:70%
    }
}

@media(max-width:500px){
    .annual_reports{
        width: 100%;
    }
}
