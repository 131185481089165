.colo {
    display: flex;
    justify-content: center;
    /* border: 2px solid #01134d; */
}

.event_card_divider {
    height: 3px;
    width: 95%;
    background-color: #ff6600;
    margin: 10px 0;
}

.event {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 2px solid #01134d;
    width: 90%;
    height: 220px;
    padding: 15px;
}

.date {
    font-size: 20px;
    color: #01134d;
    font-weight: 800;
}

.title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #01134d;
    margin-bottom: 10px;
}

.venue {
    margin-bottom: 15px;
    text-align: center;
}

.event_cal_add{
    color: #01134d;
    font-size: 14px;
    text-align: center;
    /* text-decoration: none; */
}
.status_up{
    color:#ff6600;
    font-size: 14px;
}
.status_com{
    color:#bbb;
    font-size: 14px;
}

@media (max-width:802px) {
    .date {
        font-size: 17px;
    }
    .divider {
        height: 2px;
        margin: 5px 0;
    }
    .title {
        font-size: 15px;
    }
    .time {
        font-size: 15px;
    }
    .venue {
        font-size: 12px;
    }
    .cal {
        font-size: 12px;
    }
    .event{
        padding: 5px;
    }
}