.istewhole{
    padding: 20px;
}

.isteabt{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.isteabt .isteabttext{
    width: 78%;
}
.isteabttext .isteabttextp{
    line-height: 1.5rem;
    color: black;
    font-size: 18px;
    text-align: justify;
}
.isteabtpic{
    width: 20%;
}
.isteabtpici{
    width: 100%;
    height: auto;
}

.istepdfsec{
    display:flex ;
    justify-content: center;
    align-items: center;
}

.istepdf{
    width: 70%;
}
.iste_cards{
    display: flex;
    justify-content: center;
}

@media(max-width:1350px){
    .isteabt{
        flex-direction: column-reverse; 
    }
    .isteabt .isteabttext{
        width: 100%;
    }
    .isteabt .isteabtpic{
        margin-bottom: 10px;
    }
}

@media(max-width:1024px){
    .isteabt .isteabtpic{
        width: 30%;
    }
    .istepdf{
        width: 100%;
    }
}
@media(max-width:768px){
    .isteabt.isteabtpic{
            width: 40%;
        }
    
    .istepdf{
        width:70%
    }
}

@media(max-width:600px){
    .isteabt .isteabtpic{
            width: 50%;
        }
}

@media(max-width:500px){
    .isteabt .isteabttext .isteabttextp{
                font-size: 16px;
    }
    .istepdf{
        width: 100%;
    }
}