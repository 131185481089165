.slideshow-container {
    background-color: black;
    /* height: 670px; */
    /* height: 650px; */
    /* margin: auto; */
}

.mslide {
    display: none
}

.slide_active{
    display: block !important;
}




.prev1,
.next1 {
    cursor: pointer;
    position: absolute;
    top: 45%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bolder;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 5px 5px 0;
    user-select: none;
    text-decoration: none;
    background-color: #000;
    opacity: 0.7;
}

.next1 {
    right: 0;
    border-radius: 5px 0 0 5px;
}

.prev1:hover,
.next1:hover {
    color: #ff6600;
    font-weight: bold;
    opacity: 0.7;
}
.dot1 {
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin: 0 2px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.activ {
    background-color: transparent !important;
    border: 4px solid #ff6600 !important;
}

.dot1:hover {
    background-color: #fff;
    border: 4px solid #01134d;
}

.dots1 {
    width: 200px;
    display: flex;
    justify-content: space-around;
    /* position: relative; */
    margin-top: -45px;
    z-index: 1;
    /* background-color: aquamarine; */
}

.tor {
    display: flex;
    justify-content: center;
    /* background-color: aqua; */
}


/* Fading animation */

.fades {
    animation-name: fades;
    animation-duration: 4s;
}

@keyframes fades {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
}


@media(max-width:1060px) {
    .prev1,
    .next1 {
        display: none;
        top: 17%;
        padding: 10px;
    }
}

@media(max-width:500px) {
    .dot1 {
        height: 7px;
        width: 7px;
    }
    .dots1 {
        /* background-color: aqua; */
        height: 30px;
    }
    .prev1,
    .next1 {
        font-size: 11px;
    }
    .activ {
        background-color: transparent;
        border: 2px solid #ff6600;
    }
    .dot1:hover {
        background-color: #fff;
        border: 2px solid #01134d;
    }
}

@media only screen and (max-width: 321px) {
    .prev1,
    .next1,
    .text {
        font-size: 11px
    }
}