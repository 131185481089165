.aicte_content{
    margin-top: 20px;
    padding: 20px;
}

/* .aicte_head{
    font-weight: 500;
    color: #00134d;
    font-size: 40px;
} */

.aicte_cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 0px;
}

@media(max-width:500px){
    .aicte_head{
        font-size: 28px;
    }
}

@media(max-width:321px){
    .aicte_head{
        font-size: 26px;
    }
}