html,
body {
    overflow-x: hidden;
    /* Prevent scroll on narrow devices */
}

body {
    padding-top: 88px;
    line-height: 1;
    overflow-x: hidden;
}

.gapo {
    height: 40px;
}
* {
    padding: 0;
    margin: 0;
}

@media(max-width:1200px){
    body{
        padding-top: 54px;
    }
}