@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap");

:root {
  /* Colors */
  --brand-color: hsl(46, 100%, 50%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  /* Fonts */
  --font-title: "Montserrat", sans-serif;
  --font-text: "Lato", sans-serif;
}

.dev_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none; /* Allows interactions to pass through to the image */
}

/* RESET */

/* Box sizing rules */
/* *,
*::before,
*::after {
  box-sizing: border-box;
} */

/* Remove default margin */


/* p {
  margin: 0;
} */

/* GLOBAL STYLES */
/* body {
  display: grid;
  place-items: center;
  height: 100vh;
} */

.devcard__title {
  font-size: 2rem;
  margin: 0;
  font-family: var(--font-title);
  color: var(--white);
  line-height: 1.1;
}

.devcard__description {
  font-family: var(--font-text);
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--white);
}

.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

/* CARD COMPONENT */

.devcard {
  margin: 10px;
  display: grid;
  place-items: center;
  /* width: 30vw; */
  width:350px;
  max-width: 350px;
  /* max-width: 21.875rem; */
  /* height: 28.125rem; */
  height: 450px;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.devcard > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.devcard__background {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}

.devcard__content {
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height: 45%; /*change this to move the name up*/
  width: 100%;
  padding: 12% 1.25rem 1.875rem;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.3) 10%,
    hsl(0, 0%, 0%) 100%
  );
}

.devcard__content--container {
  --flow-space: 1.25rem;
}

.devcard__title {
  position: relative;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
}

.devcard__title::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: var(--brand-color);
}

.devcard__button {
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
  font-variant: small-caps;
  font-weight: bold;
  border-radius: 0.45em;
  border: none;
  background-color: var(--brand-color);
  font-family: var(--font-title);
  font-size: 1.125rem;
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
}

.devcard__button:hover{
  color: black;
}

.devcard__button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}

@media (any-hover: hover) and (any-pointer: fine) {
  .devcard__content {
    transform: translateY(42%);
    transition: transform 500ms ease-out;
    transition-delay: 500ms;
  }

  .devcard__title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 1000ms ease-in, transform 500ms ease-out;
    transition-delay: 500ms;
    transform-origin: right;
  }

  .devcard__background {
    transition: transform 500ms ease-in;
  }

  .devcard__content--container > :not(.devcard__title),
  .devcard__button {
    opacity: 0;
    transition: transform 500ms ease-out, opacity 500ms ease-out;
  }

  .devcard:hover,
  .devcard:focus-within {
    transform: scale(1.05);
    transition: transform 500ms ease-in;
  }

  .devcard:hover .devcard__content,
  .devcard:focus-within .devcard__content {
    transform: translateY(0);
    transition: transform 500ms ease-in;
  }

  .devcard:focus-within .devcard__content {
    transition-duration: 0ms;
  }

  .devcard:hover .devcard__background,
  .devcard:focus-within .devcard__background {
    transform: scale(1.3);
  }

  .devcard:hover .devcard__content--container > :not(.devcard__title),
  .devcard:hover .devcard__button,
  .devcard:focus-within .devcard__content--container > :not(.devcard__title),
  .devcard:focus-within .devcard__button {
    opacity: 1;
    transition: opacity 500ms ease-in;
    transition-delay: 1000ms;
  }

  .devcard:hover .devcard__title::after,
  .devcard:focus-within .devcard__title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transition-delay: 500ms;
  }
}

@media(max-width:400px){
    .devcard__content {
      height: 50%;
    }
}