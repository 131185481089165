.in_this_sec {
  width: 20%;
  position: absolute;
  z-index: 1;
  left: auto;
  right: 20px;
  padding: 10px;
  background-color: #d9edff;
  border-radius: 5px 0;
}

.in_this_sec > p {
  color: #00134d;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 5px;
  border-bottom: 1px solid #ff6600;
  display: flex;
}

.in_this_sec > p > svg {
  fill: #00134d;
  height: 24px;
  width: 24px;
}

.in_this_sec > div {
  display: flex;
  flex-direction: column;
}

.in_this_sec > div > a {
  color: #00134d;
  font-size: 14px;
  padding: 13px 5px;
  border-bottom: 0.3px solid #00134d;
  text-decoration: none;
}

.in_this_sec > div > a:hover .arrow {
  transform: translateX(5px);
  transition: 0.5s ease;
  color: #ff6600;
}

.arrow {
  font-size: 12px;
  color: #00134d;
}

@media (max-width: 768px) {
  .in_this_sec {
    position: static;
    width: 100%;
  }
  
  .in_this_sec > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media (max-width: 600px) {
  .in_this_sec > div {
    display: flex;
    flex-direction: column;
  }
}
