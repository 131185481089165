.parents_fb_container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.parents_fb_title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.parents_fb_form {
  display: flex;
  flex-direction: column;
}

.parents_fb_container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.parents_fb_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}
.parents_fb_1st_input{
  width: 100%; /* Adjusts the width of each field to take half the available space with some gap */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
.parents_fb_input, .parents_fb_select {
  width: 48%; /* Adjusts the width of each field to take half the available space with some gap */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.parents_fb_select {
  width: 100%;
  margin-bottom: 15px;
}

.parents_fb_subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-top: 25px;
  text-align: center;
}

.parents_fb_instruction {
  text-align: center;
  margin-bottom: 15px;
}

.parents_fb_feedback {
  margin-top: 30px;
}

.parents_fb_question {
  margin-bottom: 20px;
}

.parents_fb_ratings {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding: 5px;
}

.parent_custom-radio {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #00134d;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  color: #00134d;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
}

input[type="radio"]:checked + .parent_custom-radio {
  background-color: #00134d;
  color: white;
}

input[type="radio"] {
  display: none;
}

.parents_fb_ratings label {
  display: flex;
  align-items: center;
}

.parents_fb_ratings input {
  margin-right: 5px;
}

.parents_fb_btn {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.parents_fb_error {
  color: red;
  font-size: 14px;
  margin-top: 0px;
  }
.parents_year_of_joining_container{
  color: red;
font-size: 14px;
margin-top: 5px;
margin-left:52%;
}
.parents_student_fb_error{
  color: red;
font-size: 14px;
margin-top: 5px;
}

@media (max-width: 600px) {
  .parents_fb_row {
      flex-direction: column;
  }

  .parents_fb_input, .parents_fb_select {
      width: 100%;
  }
}
@media (max-width:600px) {
  .parents_year_of_joining_container{
    color: red;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 40%;
  
  }
}
