*{
  margin: 30px;
}
.practical_fb_container{
    
    max-width: 800px;
    margin:  40px auto;
    padding: 20px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff; 
}
.practical_fb_form{
display: flex;
flex-direction: column;
}

.std_practical_fb_error {
  color: red;
  font-size: 14px;
  margin-top: -30px;
}

.std_pract_fb_error p,.std_practical_fb_sem_err p{
  
  color: red;
  font-size: 14px;
  margin-top :5px;
  margin-left: 51%;
}


.practical_fb_rows{

display: flex;
flex-wrap: wrap;
justify-content: space-around;
margin-bottom: 10px;

}
.practical_fb_input,.practical_fb_select{
width: 49%;
padding: 12px;
border: 1px solid #ccc;
border-radius: 5px;

}
.practical_fb_input_full{
width: 99%;
padding: 12px;

}

.practical_fb_faculty_line{
  margin-top: 32px;
}

.practical_fb_subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-top: 25px;
  text-align: center;
}

.practical_fb_instruction{
  text-align: center;
  margin-bottom: 15px;
  margin-top:10px;
}

.custom-radio {
    display: inline-block;
    width: 20px; /* Adjust the outer circle size */
    height: 20px;
    border: 2px solid #00134d;
    border-radius: 50%;
    margin-right: 5px;
    position: relative;
    text-align: center;
    line-height: 18px; /* Adjust to center the number vertically */
    font-size: 12px; /* Font size for the number */
    color: #00134d;
    background-color: white;
    font-weight: bold;
  }
  
  input[type="radio"]:checked + .custom-radio {
    background-color: #00134d;
    color: white; /* Change number color when selected */
  }
  
  input[type="radio"] {
    display: none;
  }
  .practical_fb_data{
    display: flex;
    justify-content: space-evenly;
    margin: 30px;
  }

  .practical_fb_submit{
    display: flex;
   margin: 5px auto;

  }
  
  @media (max-width: 600px) {
    .practical_fb_rows {
      flex-direction: column;
    }
  
    .practical_fb_input, .practical_fb_select {
      width: 100%;
    }
    .practical_fb_two_line{
      margin-top: 10px;
    }
    .practical_fb_rating{
      text-align: center;
    }
    .std_pract_fb_error p,.std_practical_fb_sem_err p{
      margin-left: auto;
    }
  }