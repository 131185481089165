.log-inputs{
    display: flex;
    justify-content: center;
    align-items: center;
}
.log-input{
    width: 80px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.5);
    margin: 0 10px;
    text-align: center;
    font-size: 20px;
    /* color: black; */
    background: #e6e6e6;
  color: #333;
  /* letter-spacing: 0.5px; */
  padding: 10px ;
  font-family: 'Montserrat', sans-serif;
  border: none;
  /* outline: none; */
  border-radius: 30px;
  font-size: 1.1em;
}
.log-input:focus{
    border-bottom: 3px solid #ff6600;
    outline: none;
}
.log-input:nth-child(1){
    cursor: pointer;
    pointer-events: all;
}
.log-input[type=number]{
    appearance: textfield;
}

.otp_err_msg{
    color: red;
  font-size: 14px;
  font-weight: 700;
  padding: 0.75rem 0.75rem 0 0.75rem;
}

.login-submit_passwdrec{
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 1.1em;
    color: #fff;
    padding: 14px 64px;
    margin: 32px auto;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    background: #ff6600;
    /* background-image: linear-gradient(to right, #ff6600, #fff); */
    cursor: pointer;
    transition: opacity 0.4s;
}

.login-submit_passwdrec:hover{
    opacity: 0.9;
    /* background-image: linear-gradient(to right, #ff6600, #00134d); */
    background: #00134d;
    color: white;
    text-transform: lowercase;
}

@media(max-width:896px){
    .log-input{
        width: 60px;
    }
}