@media (max-width: 1050px) {
    .cse_mou_tab{
        overflow-x:scroll;
    }
}

@media (max-width: 768px) {
    .cse_mou_tab{
        margin-top:25px;
    }
}