* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .placementbg {
    /* background: url("../../assets/pictures/csebg.webp"); */
    background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/dept_bgs/Placement_bg.webp");
    min-height: 550px;
    background-color: #00134d;
    position: relative;
    width: 100%;
    height: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .placementheading {
    text-align: center;
    position: absolute;
    color: #fff;
    font-size: 250%;
    top: 48%;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-shadow: 0px 0px 10px #000;
  }
  .placement_sub_heading {
    text-align: center;
    position: absolute;
    color: #ff6600;
    font-size: 150%;
    top: 75%;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-shadow: 0px 0px 10px #000;
  }
  .placementtwo-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .placementdark {
    /* display: flex;
      flex-direction: column; */
    padding: 40px 20px;
    width: 75%;
    /* border-radius: 5px 0 5px 0; */
  }
  .placement_sub_head {
    font-size: 40px;
    font-weight: 500;
    /* width: 100%;  */
    /* text-align: left;  */
    color: #00134d;
  }
  .placementtwo-col p {
    margin-top: 20px;
    width: 50%;
    padding: 0 30px;
  }
  .placement_content {
    text-align: justify;
    font-size: 18px;
    line-height: 1.5rem;
    color: black;
  }
  .placementtwo-col .dept_img_holder {
    width: 50%;
    /* height: auto; */
    /* align-items: center;
      justify-content: center; */
  }
  .dept_img {
    width: 100%;
    height: auto;
  }
  .placementtwo-col1 p {
    width: 100%;
    padding: 20px 30px;
  }
  @media(max-width:1100px){
    .placementtwo-col {
      
      flex-direction: column;
    }
    .placementtwo-col p {
      width: 100%;
    }
    .placementtwo-col .dept_img_holder {
      width: 90%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 1024px) {
    .placementheading {
      top: 35%;
      font-size: 150%;
      font-weight: 500;
    }
    .placement_sub_heading {
      font-size: 100%;
      font-weight: 500;
    }
    .placementbg {
      width: 100%;
      height: 700px;
    }
  }
  @media (max-width: 768px){
      .placementbg{
          width: 100%;
          height: 500px;
      }
      .placementdark{
          width: 100%;
      }
      .placementtwo-col{
          display: flex;
          flex-direction: column;
      }
      .placementtwo-col p{
          width: 100%;
      }
      .placementtwo-col .dept_img_holder{
          width: 90%;
          margin-top: 20px;
          margin-bottom: 20px;
      }
      
  }
  @media (max-width: 425px){
      .placementbg{
          /* background: url("../../assets/pictures/csebgm.webp"); */
          background: url("https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/dept_bgs/Placement_bgm.webp");
          min-height: 550px;
          width: 100%;
          height: 750px;
          background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }
    .placementtwo-col p {
      padding: 0 5px;
    }
    .placementtwo-col1 p {
      padding: 0 5px;
    }
  }
  
  @media (max-width: 321px) {
    .placement_sub_head {
      font-size: 26px;
    }
  }
  